import React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import LogoMobile from "./LogoMobile"

const ItemWrapper = styled.ul`
  position: fixed;
  top: ${props => props.top || "-100vh"};
  opacity: ${props => props.opacity || 0};
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  z-index: 100;
  transition: all 0.3s ease-in-out;
  ${up("md")} {
    display: none;
  }

  a {
    color: ${({ theme }) => theme.color.font};
    //background-color: ${({ theme }) => theme.color.baseLight};
    text-align: left;
    display: block;
    font-size: 20px;
    padding: 1.5rem 1rem 1.5rem 1rem;
    transition: all 0.5s;
    border-style: none;

    :first-child {
      padding: 0;
      //margin-bottom: 1rem;
    }
  }

  li {
    height: 100px;
    display: block;
    margin: 0;
    padding: 2rem;

    :hover,
    &.active {
      background-color: ${({ theme }) => theme.color.grey8};
      color: ${({ theme }) => theme.color.font};
    }
  }
`

const NavMobile = props => {
  return (
    <ItemWrapper top={props.top} opacity={props.opacity}>
      <LogoMobile
        position="relative"
        onClick={props.onClick}
        state={props.state}
      />
      {props.children}
    </ItemWrapper>
  )
}
export default NavMobile
