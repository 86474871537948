import { color } from "./colorContrast"

const fontSizeUnit = "em"

export const themeContrast = {
  color,

  breakpoints: {
    xs: "0px",
    sm: "576px",
    md: "980px",
    lg: "1320px",
    xl: "1921px",
  },

  font: {
    main: "Lexend",
    header: "Lexend",
    special: "Lexend",

    weight: 300,
    weightBold: 800,
    weightH: 800,

    sizeSmall: "14px",

    size: {
      body: "10px",
      p: {
        xs: `1.6${fontSizeUnit}`,
        s: `1.7${fontSizeUnit}`,
        m: `1.8${fontSizeUnit}`,
        l: `1.9${fontSizeUnit}`,
        xl: `2.0${fontSizeUnit}`,
      },

      l: {
        xs: `1.4${fontSizeUnit}`,
        s: `1.5${fontSizeUnit}`,
        m: `1.6${fontSizeUnit}`,
        l: `1.7${fontSizeUnit}`,
        xl: `1.8${fontSizeUnit}`,
      },

      h1: {
        xs: `4.0${fontSizeUnit}`,
        s: `4.2${fontSizeUnit}`,
        m: `4.4${fontSizeUnit}`,
        l: `4.6${fontSizeUnit}`,
        xl: `4.8${fontSizeUnit}`,
      },

      h2: {
        xs: `3.2${fontSizeUnit}`,
        s: `3.4${fontSizeUnit}`,
        m: `3.6${fontSizeUnit}`,
        l: `3.8${fontSizeUnit}`,
        xl: `4.0${fontSizeUnit}`,
      },

      h3: {
        xs: `2.4${fontSizeUnit}`,
        s: `2.6${fontSizeUnit}`,
        m: `2.8${fontSizeUnit}`,
        l: `3.0${fontSizeUnit}`,
        xl: `3.2${fontSizeUnit}`,
      },

      h4: {
        xs: `2.2${fontSizeUnit}`,
        s: `2.3${fontSizeUnit}`,
        m: `2.4${fontSizeUnit}`,
        l: `2.5${fontSizeUnit}`,
        xl: `2.6${fontSizeUnit}`,
      },

      h5: {
        xs: `1.9${fontSizeUnit}`,
        s: `2.0${fontSizeUnit}`,
        m: `2.1${fontSizeUnit}`,
        l: `2.2${fontSizeUnit}`,
        xl: `2.3${fontSizeUnit}`,
      },

      h6: {
        xs: `1.7${fontSizeUnit}`,
        s: `1.8${fontSizeUnit}`,
        m: `1.9${fontSizeUnit}`,
        l: `2.0${fontSizeUnit}`,
        xl: `2.1${fontSizeUnit}`,
      },

      h7: {
        xs: `1.1${fontSizeUnit}`,
        s: `1.8${fontSizeUnit}`,
        m: `1.9${fontSizeUnit}`,
        l: `2.0${fontSizeUnit}`,
        xl: `2.1${fontSizeUnit}`,
      },
    },

    lineH: {
      p: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },

      l: {
        xs: "1.1",
        s: "1.1",
        m: "1.1",
        l: "1.1",
        xl: "1.1",
      },

      h1: {
        xs: "1.2",
        s: "1.2",
        m: "1.2",
        l: "1.2",
        xl: "1.2",
      },

      h2: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },

      h3: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },

      h4: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },

      h5: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },

      h6: {
        xs: "1.5",
        s: "1.5",
        m: "1.5",
        l: "1.6",
        xl: "1.7",
      },
    },
  },
}
