import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAdjust, faSitemap } from "@fortawesome/free-solid-svg-icons"
import { ContainerMob } from "../../utils/utils"
import { Link } from "gatsby"

const WcagWrapper = styled.div`
  ${down("sm")} {
    display: none;
  }
  //margin-left: 1rem;
  background-color: ${props => props.theme.color.grey9};

  > div {
    display: flex;
    justify-content: flex-end;
    align-items: stretch;

    > button {
      //background-color: ${props => props.theme.color.body};
      background-color: transparent;
      color: ${props => props.theme.color.font};
      display: block;
      padding: 0;
      text-align: left;
      position: relative;
      padding-left: 2rem;
      padding-right: 2rem;
      border-left: 1px solid grey;

      :focus {
        border-color: ${({ theme }) => theme.color.grey5};
        border-width: 2px;
        background-color: transparent;
        outline: none;
      }

      :hover {
        background-color: ${props => props.theme.color.grey8};
      }
    }
  }

  a {
    padding: 0 1rem;
    background-color: ${props => props.theme.color.body};
    background-color: transparent;
    border-left: 1px solid grey;
    border-right: 1px solid grey;
    cursor: pointer;

    :hover {
      background-color: ${props => props.theme.color.grey8};
      color: ${props => props.theme.color.font};
    }
  }

  ul {
    display: flex;
    align-content: stretch;
    list-style-type: none;
    li {
      margin-left: 0;
      margin-right: 0;
      align-self: stretch;
      display: flex;

      button {
        padding: 0 1rem;
        width: 100%;
        //background-color: ${props => props.theme.color.body};
        background-color: transparent;
        color: ${props => props.theme.color.font};
        display: block;
        text-align: left;
        position: relative;

        :hover {
          background-color: ${props => props.theme.color.grey9};
        }
      }

      .active {
        background-color: ${props => props.theme.color.grey5};
        color: ${props => props.theme.color.white};
      }

      :nth-child(1) {
        button {
          font-size: 20px;
          line-height: 1;
          border-left: 1px solid grey;
        }
      }
      :nth-child(2) {
        button {
          font-size: 20px;
        }
      }
      :nth-child(3) {
        button {
          font-size: 20px;
        }
      }
    }
  }
`

const Wcag = props => {
  return (
    <WcagWrapper>
      <ContainerMob>
        <ul>
          <li>
            <button
              onClick={props.big}
              tabIndex="0"
              className={props.fs === "big" ? "active" : ""}
            >
              +A
            </button>
          </li>
          <li>
            <button
              onClick={props.normal}
              tabIndex="0"
              className={props.fs === "normal" ? "active" : ""}
            >
              A
            </button>
          </li>
          <li>
            <button
              onClick={props.small}
              tabIndex="0"
              className={props.fs === "small" ? "active" : ""}
            >
              -A
            </button>
          </li>
        </ul>
        <button onClick={props.contrast} tabIndex="0">
          <FontAwesomeIcon icon={faAdjust} />
          {props.th === `dark` ? " Kolory standardowe" : " Wysoki kontrast"}
        </button>
        <Link tabIndex="0" to="/mapa">
          <FontAwesomeIcon icon={faSitemap} /> Mapa strony
        </Link>
      </ContainerMob>
    </WcagWrapper>
  )
}
export default Wcag
