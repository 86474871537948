import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { down } from "styled-breakpoints"
import { LogoFe, LogoNcbr, LogoPl, LogoUE } from "../../assets/images/svg/wcag"

const NavbarInfo = styled.div`
  padding: 0;
  background-color: ${props => props.bgColor || "transparent"};
  border-bottom: 1px solid
    ${props => props.spanColor || props.theme.color.grey8};
  display: flex;
  justify-content: space-around;
  min-height: 100px;
  align-items: center;

  > div {
    height: 100%;
    width: 180px;
  }

  ${down("sm")} {
    padding-right: 80px;
  }

  ${down("xs")} {
    padding-right: 80px;
    flex-wrap: wrap;

    > div {
      height: 100%;
      width: 100px;
    }
  }

  p {
    font-weight: 300;
    text-align: center;
    color: ${props => props.fontColor || props.theme.color.font};

    span {
      font-weight: 700;
      color: ${props => props.spanColor || props.theme.color.base1};
    }
  }
`

const NavbarHeader = props => {
  return (
    <NavbarInfo bgColor={props.bgColor}>
      <LogoFe />
      <LogoPl />
      {/* <LogoNcbr /> */}
      <Link to="/projekt">
      <LogoUE />
      </Link>
    </NavbarInfo>
  )
}
export default NavbarHeader
