import React from "react"
import styled from "styled-components"
import { down } from "styled-breakpoints"

const Logo = styled.svg`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 240px;
  g path {
    fill: ${props => props.theme.color.font};
  }
  ${down("xs")} {
    width: 120px;
  }
`
const Fe = styled(Logo)`
  height: 80px;
  g polygon,
  g rect {
    fill: ${props => props.theme.color.font};
  }
`

// const Logo = styled.svg`
//   padding-top: 0.5rem;
//   padding-bottom: 0.5rem;
//   width: 240px;

//   ${down("xs")} {
//     width: 120px;
//   }
// `
// const Fe = styled(Logo)`
// 	height: 80px;
//     g polygon,

// `

const Ue = styled(Logo)`
  height: 60px;
`

const Pl = styled(Logo)`
  height: 60px;
`

const Ncbr = styled(Logo)`
  height: 80px;
`

export const LogoNcbr = () => {
  return (
    <Ncbr x="0px" y="0px" viewBox="0 0 574.102 201.825">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#B1B2B3"
        d="M176.632,47.006c0,12.234-13.427,20.122-36.866,20.122h-17.974
	V27.517h17.29C164.573,27.517,176.632,33.315,176.632,47.006L176.632,47.006z M285.037,201.773
	c-78.408,1.891-74.843-48.158-72.509-59.491c7.816-37.996,10.935-51.611-48.183-69.034c23.21-3.54,35.959-13.851,35.959-28.5
	c0-19.806-19.12-29.951-56.666-29.951H99.032v94.457h22.76V80.011C256.776,94.054,185.812,175.016,285.037,201.773z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#565655"
        d="M23.971,68.841c0,11.092,13.919,17.107,40.863,17.107h17.81V51.996
	H62.095C36.755,51.996,23.971,57.878,23.971,68.841L23.971,68.841z M28.529,25.796c0,9.895,12.79,15.774,36.989,15.774h17.125
	V10.695H63.464C40.635,10.695,28.529,16.042,28.529,25.796L28.529,25.796z M0,69.907c0-12.3,12.094-20.719,35.38-23.926
	C15.294,42.777,4.792,35.152,4.792,24.462C4.792,9.356,24.199,0,60.955,0h44.518v97.043H60.721C21.228,97.043,0,86.886,0,69.907z"
      />
      <g>
        <g>
          <path
            fill="#B82627"
            d="M296.399,88.165h-3.215L280.926,62.79v25.375h-2.486V58.418h3l12.473,25.847V58.418h2.486V88.165z"
          />
          <path
            fill="#B82627"
            d="M315.815,88.165h-2.271l-0.344-2.529c-1.228,1.943-3.029,2.915-5.401,2.915
			c-2.056,0-3.658-0.564-4.8-1.693c-1.144-1.128-1.714-2.693-1.714-4.693c0-4.715,3.285-7.072,9.858-7.072l2.057,0.086v-1.586
			c0-3.487-1.577-5.229-4.732-5.229c-1.923,0-3.601,0.757-5.034,2.271l-0.473-2.529c1.662-1.313,3.568-1.972,5.718-1.972
			c2.58,0,4.399,0.686,5.461,2.058c0.975,1.286,1.461,3.401,1.461,6.343v9.645C315.601,85.665,315.672,86.993,315.815,88.165z
			 M313.201,78.949v-1.757c-1.55-0.086-2.34-0.128-2.368-0.128c-4.708,0-7.061,1.672-7.061,5.015c0,1.343,0.365,2.394,1.096,3.15
			c0.733,0.758,1.732,1.136,2.994,1.136C311.42,86.364,313.201,83.893,313.201,78.949z"
          />
          <path
            fill="#B82627"
            d="M329.704,68.833h-0.729c-1.457,0-2.613,0.515-3.472,1.543c-0.915,1.115-1.371,2.672-1.371,4.672v13.116
			h-2.4V72.263c0-1.629-0.059-3.543-0.172-5.744h2.229c0.086,1.143,0.13,2.372,0.13,3.686c1.113-2.629,3.042-3.986,5.786-4.072
			V68.833z"
          />
          <path
            fill="#B82627"
            d="M347.748,77.363c0,3.629-0.7,6.401-2.1,8.315c-1.401,1.915-3.387,2.872-5.959,2.872
			c-2.571,0-4.55-0.958-5.936-2.872c-1.386-1.914-2.079-4.686-2.079-8.315c0-3.628,0.699-6.408,2.101-8.336
			c1.399-1.929,3.371-2.894,5.914-2.894s4.522,0.973,5.938,2.915C347.041,70.992,347.748,73.763,347.748,77.363z M345.176,77.363
			c0-5.972-1.829-8.958-5.487-8.958c-3.629,0-5.442,2.986-5.442,8.958c0,5.944,1.813,8.916,5.442,8.916
			C343.347,86.279,345.176,83.307,345.176,77.363z"
          />
          <path
            fill="#B82627"
            d="M366.522,88.165h-2.015c-0.028-0.229-0.071-1.271-0.128-3.129c-1.372,2.344-3.13,3.515-5.272,3.515
			c-5.059,0-7.587-3.729-7.587-11.188c0-7.486,2.528-11.23,7.587-11.23c2.143,0,3.814,1.058,5.015,3.172V56.232h2.4V88.165z
			 M364.122,78.735v-2.787c0-2.457-0.45-4.343-1.35-5.658c-0.9-1.314-2.137-1.972-3.708-1.972c-3.315,0-4.973,3.015-4.973,9.044
			c0,6.03,1.657,9.044,4.973,9.044c1.571,0,2.808-0.664,3.708-1.993C363.671,83.085,364.122,81.193,364.122,78.735z"
          />
          <path
            fill="#B82627"
            d="M387.652,77.363c0,3.629-0.7,6.401-2.101,8.315c-1.4,1.915-3.386,2.872-5.958,2.872
			c-2.571,0-4.55-0.958-5.936-2.872c-1.386-1.914-2.079-4.686-2.079-8.315c0-3.628,0.699-6.408,2.101-8.336
			c1.399-1.929,3.371-2.894,5.914-2.894c2.544,0,4.522,0.973,5.938,2.915C386.945,70.992,387.652,73.763,387.652,77.363z
			 M385.08,77.363c0-5.972-1.828-8.958-5.486-8.958c-3.628,0-5.443,2.986-5.443,8.958c0,5.944,1.815,8.916,5.443,8.916
			C383.251,86.279,385.08,83.307,385.08,77.363z"
          />
          <path
            fill="#B82627"
            d="M417.142,66.519l-5.787,21.646h-2.785l-4.973-18.731l-4.587,18.731h-2.742l-5.7-21.646h2.485l4.586,18.988
			l4.715-18.988h2.743l4.887,18.946l4.629-18.946H417.142z"
          />
          <path
            fill="#B82627"
            d="M434.543,75.906c0,0.715-0.014,1.415-0.042,2.101h-11.874c0,2.686,0.522,4.736,1.565,6.151
			c1.042,1.415,2.521,2.122,4.436,2.122c1.715,0,3.329-0.5,4.844-1.5v2.529c-1.485,0.829-3.214,1.243-5.187,1.243
			c-2.657,0-4.7-0.958-6.129-2.872c-1.43-1.914-2.144-4.7-2.144-8.358c0-3.485,0.699-6.243,2.101-8.272
			c1.372-1.942,3.186-2.915,5.443-2.915c2.171,0,3.879,0.851,5.122,2.551C433.923,70.384,434.543,72.792,434.543,75.906z
			 M432.058,75.863c0-4.972-1.486-7.458-4.458-7.458s-4.615,2.486-4.93,7.458H432.058z"
          />
          <path
            fill="#B82627"
            d="M466.347,84.993l-0.129,2.658c-1.516,0.6-3.243,0.9-5.186,0.9c-3.63,0-6.588-1.372-8.873-4.115
			c-2.372-2.83-3.559-6.615-3.559-11.359c0-4.629,1.137-8.301,3.408-11.016c2.272-2.715,5.265-4.072,8.979-4.072
			c1.601,0,3.301,0.286,5.101,0.857l0.086,2.872c-1.515-0.915-3.229-1.372-5.143-1.372c-3.029,0-5.409,1.098-7.137,3.295
			c-1.729,2.197-2.594,5.278-2.594,9.243c0,4.194,0.942,7.474,2.828,9.843c1.801,2.311,4.229,3.465,7.287,3.465
			C463.132,86.193,464.775,85.793,466.347,84.993z"
          />
          <path
            fill="#B82627"
            d="M484.091,75.906c0,0.715-0.014,1.415-0.042,2.101h-11.874c0,2.686,0.522,4.736,1.565,6.151
			c1.042,1.415,2.521,2.122,4.437,2.122c1.714,0,3.328-0.5,4.843-1.5v2.529c-1.485,0.829-3.214,1.243-5.186,1.243
			c-2.658,0-4.701-0.958-6.129-2.872c-1.431-1.914-2.145-4.7-2.145-8.358c0-3.485,0.7-6.243,2.101-8.272
			c1.372-1.942,3.186-2.915,5.443-2.915c2.172,0,3.88,0.851,5.123,2.551C483.471,70.384,484.091,72.792,484.091,75.906z
			 M481.606,75.863c0-4.972-1.486-7.458-4.457-7.458c-2.973,0-4.615,2.486-4.931,7.458H481.606z"
          />
          <path
            fill="#B82627"
            d="M502.694,88.165h-2.4V73.12c0-3.171-1.272-4.757-3.815-4.757c-1.543,0-2.778,0.593-3.707,1.778
			c-0.93,1.186-1.394,2.822-1.394,4.908v13.116h-2.4V72.263c0-1.629-0.057-3.543-0.171-5.744h2.229c0,0.058,0.043,1.101,0.129,3.129
			c1.2-2.344,3.058-3.516,5.572-3.516c1.857,0,3.314,0.594,4.372,1.779c1.056,1.186,1.586,2.879,1.586,5.079V88.165z"
          />
          <path
            fill="#B82627"
            d="M515.852,87.907c-0.886,0.314-1.729,0.472-2.528,0.472c-1.829,0-3.029-0.585-3.601-1.758
			c-0.429-0.885-0.643-2.628-0.643-5.229V68.662h-3.172v-2.143h3.172v-4.372l2.4-0.686v5.058h4.371v2.143h-4.371v13.459
			c0,1.315,0.1,2.215,0.3,2.701c0.344,0.829,1.085,1.243,2.229,1.243c0.543,0,1.157-0.113,1.843-0.343V87.907z"
          />
          <path
            fill="#B82627"
            d="M527.725,68.833h-0.729c-1.458,0-2.615,0.515-3.473,1.543c-0.915,1.115-1.371,2.672-1.371,4.672v13.116
			h-2.4V72.263c0-1.629-0.058-3.543-0.172-5.744h2.229c0.086,1.143,0.129,2.372,0.129,3.686c1.114-2.629,3.043-3.986,5.786-4.072
			V68.833z"
          />
          <path
            fill="#B82627"
            d="M544.657,88.165h-2.229c-0.029-0.171-0.071-1.214-0.129-3.129c-1.2,2.344-3.059,3.515-5.572,3.515
			c-1.858,0-3.315-0.585-4.372-1.757c-1.057-1.171-1.586-2.857-1.586-5.058V66.519h2.4v15.045c0,3.172,1.271,4.757,3.815,4.757
			c1.543,0,2.778-0.592,3.707-1.778c0.928-1.186,1.394-2.822,1.394-4.908V66.519h2.4v15.902
			C544.485,84.193,544.542,86.107,544.657,88.165z"
          />
          <path
            fill="#B82627"
            d="M574.102,88.165h-2.4V73.12c0-3.171-1.143-4.757-3.429-4.757c-1.372,0-2.486,0.593-3.343,1.778
			c-0.857,1.186-1.287,2.822-1.287,4.908v13.116h-2.399V73.12c0-3.171-1.13-4.757-3.387-4.757c-1.372,0-2.486,0.593-3.344,1.778
			c-0.856,1.186-1.285,2.822-1.285,4.908v13.116h-2.4V72.263c0-1.629-0.058-3.543-0.172-5.744h2.229
			c0,0.058,0.043,1.101,0.128,3.129c1.172-2.344,2.886-3.516,5.144-3.516c1.172,0,2.208,0.308,3.108,0.922
			c0.899,0.615,1.521,1.436,1.863,2.464c1.4-2.257,3.229-3.387,5.487-3.387c1.714,0,3.058,0.594,4.029,1.779
			c0.971,1.186,1.457,2.879,1.457,5.079V88.165z"
          />
          <path
            fill="#B82627"
            d="M293.356,130.813c0,3.058-0.865,5.287-2.594,6.686c-1.729,1.4-4.422,2.1-8.08,2.1h-4.243v-29.747h5.53
			c5.828,0,8.742,2.429,8.742,7.287c0,3.744-1.643,6.015-4.928,6.815C291.498,124.812,293.356,127.099,293.356,130.813z
			 M290.012,117.611c0-2-0.572-3.414-1.715-4.243c-1.029-0.771-2.7-1.158-5.014-1.158h-2.357v10.716h3.043
			C287.998,122.926,290.012,121.155,290.012,117.611z M290.741,131.306c0-4.015-2.258-6.022-6.771-6.022h-3.043v11.959h3.043
			C288.483,137.243,290.741,135.265,290.741,131.306z"
          />
          <path
            fill="#B82627"
            d="M310.843,139.6h-2.271l-0.344-2.528c-1.229,1.943-3.029,2.915-5.4,2.915c-2.058,0-3.658-0.564-4.802-1.693
			c-1.143-1.128-1.713-2.693-1.713-4.694c0-4.715,3.285-7.072,9.858-7.072l2.057,0.086v-1.586c0-3.486-1.577-5.229-4.733-5.229
			c-1.922,0-3.6,0.757-5.032,2.272l-0.473-2.529c1.661-1.314,3.567-1.972,5.718-1.972c2.578,0,4.398,0.686,5.46,2.057
			c0.975,1.286,1.461,3.401,1.461,6.344v9.644C310.628,137.1,310.7,138.429,310.843,139.6z M308.228,130.385v-1.758
			c-1.551-0.085-2.34-0.128-2.368-0.128c-4.709,0-7.062,1.672-7.062,5.015c0,1.343,0.366,2.394,1.098,3.15
			c0.732,0.757,1.73,1.136,2.993,1.136C306.448,137.8,308.228,135.328,308.228,130.385z"
          />
          <path
            fill="#B82627"
            d="M330.474,139.6h-2.015c-0.028-0.228-0.071-1.271-0.128-3.128c-1.372,2.344-3.13,3.515-5.272,3.515
			c-5.058,0-7.588-3.729-7.588-11.187c0-7.486,2.53-11.23,7.588-11.23c2.143,0,3.814,1.058,5.015,3.172v-13.073h2.4V139.6z
			 M328.074,130.17v-2.786c0-2.458-0.45-4.344-1.35-5.658c-0.9-1.313-2.137-1.971-3.708-1.971c-3.315,0-4.973,3.015-4.973,9.044
			s1.657,9.043,4.973,9.043c1.571,0,2.808-0.664,3.708-1.993C327.624,134.521,328.074,132.628,328.074,130.17z"
          />
          <path
            fill="#B82627"
            d="M349.461,139.6h-2.271l-0.344-2.528c-1.229,1.943-3.029,2.915-5.4,2.915c-2.058,0-3.658-0.564-4.801-1.693
			c-1.144-1.128-1.713-2.693-1.713-4.694c0-4.715,3.284-7.072,9.857-7.072l2.057,0.086v-1.586c0-3.486-1.578-5.229-4.732-5.229
			c-1.923,0-3.601,0.757-5.033,2.272l-0.473-2.529c1.661-1.314,3.567-1.972,5.718-1.972c2.578,0,4.398,0.686,5.459,2.057
			c0.975,1.286,1.461,3.401,1.461,6.344v9.644C349.246,137.1,349.318,138.429,349.461,139.6z M346.846,130.385v-1.758
			c-1.55-0.085-2.339-0.128-2.367-0.128c-4.71,0-7.063,1.672-7.063,5.015c0,1.343,0.366,2.394,1.098,3.15
			c0.732,0.757,1.73,1.136,2.993,1.136C345.066,137.8,346.846,135.328,346.846,130.385z"
          />
          <path
            fill="#B82627"
            d="M369.093,139.6h-2.4v-15.044c0-3.172-1.272-4.758-3.815-4.758c-1.542,0-2.779,0.593-3.707,1.779
			c-0.93,1.186-1.394,2.822-1.394,4.908V139.6h-2.4v-15.901c0-1.629-0.057-3.543-0.172-5.744h2.229c0,0.057,0.043,1.1,0.129,3.129
			c1.2-2.343,3.058-3.515,5.572-3.515c1.856,0,3.314,0.593,4.372,1.779c1.057,1.186,1.586,2.879,1.586,5.079V139.6z
			 M366.178,109.424l-4.072,6.13h-2.187l3.087-6.13H366.178z"
          />
          <path
            fill="#B82627"
            d="M388.038,113.025h-2.871v-3.601h2.871V113.025z M387.781,139.6h-2.4v-21.645h2.4V139.6z"
          />
          <path
            fill="#B82627"
            d="M420.912,139.6h-2.786l-3.515-9.901c-0.6-1.686-1.186-2.771-1.757-3.257s-1.658-0.729-3.257-0.729h-2.401
			V139.6h-2.485v-29.747h4.285c3.458,0,5.973,0.544,7.544,1.631c1.715,1.173,2.572,3.176,2.572,6.008
			c0,4.12-2.058,6.523-6.172,7.209c1.113,0.085,1.972,0.457,2.571,1.113c0.485,0.543,0.985,1.542,1.5,2.997L420.912,139.6z
			 M416.412,117.417c0-2.066-0.614-3.471-1.844-4.217c-1.058-0.66-2.887-0.99-5.485-0.99h-1.887v11.145h1.586
			c2.715,0,4.665-0.43,5.85-1.291C415.818,121.204,416.412,119.655,416.412,117.417z"
          />
          <path
            fill="#B82627"
            d="M439.47,128.799c0,3.629-0.699,6.401-2.1,8.315c-1.399,1.914-3.386,2.872-5.958,2.872
			c-2.571,0-4.55-0.958-5.937-2.872c-1.386-1.915-2.079-4.687-2.079-8.315s0.7-6.408,2.101-8.337c1.4-1.929,3.371-2.893,5.915-2.893
			c2.543,0,4.522,0.972,5.937,2.915C438.763,122.427,439.47,125.198,439.47,128.799z M436.9,128.799c0-5.972-1.83-8.958-5.487-8.958
			c-3.629,0-5.443,2.986-5.443,8.958c0,5.944,1.814,8.915,5.443,8.915C435.07,137.714,436.9,134.743,436.9,128.799z"
          />
          <path
            fill="#B82627"
            d="M455.459,139.6h-12.944v-2.614l10.243-16.888H442.9v-2.143h12.389v2.614l-10.245,16.888h10.416V139.6z"
          />
          <path
            fill="#B82627"
            d="M484.434,117.955l-5.786,21.645h-2.787l-4.971-18.73l-4.587,18.73h-2.743l-5.701-21.645h2.487
			l4.586,18.988l4.715-18.988h2.743l4.885,18.945l4.631-18.945H484.434z"
          />
          <path
            fill="#B82627"
            d="M503.463,128.799c0,3.629-0.699,6.401-2.1,8.315c-1.399,1.914-3.386,2.872-5.958,2.872
			s-4.551-0.958-5.937-2.872c-1.386-1.915-2.079-4.687-2.079-8.315s0.701-6.408,2.101-8.337c1.4-1.929,3.371-2.893,5.915-2.893
			c2.543,0,4.522,0.972,5.937,2.915C502.756,122.427,503.463,125.198,503.463,128.799z M500.892,128.799
			c0-5.972-1.829-8.958-5.486-8.958c-3.629,0-5.443,2.986-5.443,8.958c0,5.944,1.814,8.915,5.443,8.915
			C499.063,137.714,500.892,134.743,500.892,128.799z"
          />
          <path
            fill="#B82627"
            d="M510.88,141.143c0,2.857-0.204,4.786-0.611,5.787c-0.554,1.457-1.707,2.186-3.462,2.186
			c-0.742,0-1.399-0.085-1.971-0.257v-2.314c0.514,0.143,1,0.214,1.457,0.214c0.943,0,1.558-0.329,1.843-0.986
			c0.2-0.514,0.301-1.614,0.301-3.3v-24.517h2.443V141.143z M511.094,113.025h-2.915v-3.601h2.915V113.025z"
          />
          <path
            fill="#B82627"
            d="M530.725,139.6h-2.229c-0.029-0.171-0.071-1.214-0.128-3.128c-1.2,2.344-3.059,3.515-5.572,3.515
			c-1.858,0-3.316-0.585-4.372-1.757c-1.058-1.171-1.586-2.857-1.586-5.058v-15.216h2.399v15.044c0,3.172,1.271,4.758,3.815,4.758
			c1.543,0,2.778-0.592,3.707-1.779c0.928-1.185,1.394-2.821,1.394-4.908v-13.116h2.4v15.902
			C530.553,135.629,530.611,137.543,530.725,139.6z"
          />
        </g>
      </g>
    </Ncbr>
  )
}

export const LogoUE = () => {
  return (
    <Ue x="0px" y="0px" viewBox="0 0 302 68">
      <g>
        <g>
          <rect x="0" y="0" fill="#164395" width="102.037" height="68.024" />
          <polygon
            fill="#FFED00"
            points="48.844,14.827 51.002,13.253 53.153,14.827 52.33,12.286 54.52,10.714 51.824,10.714 51.002,8.143 50.179,10.714 47.475,10.714 49.658,12.286"
          />
          <polygon
            fill="#FFED00"
            points="37.7,17.816 39.858,16.243 42.009,17.816 41.188,15.277 43.376,13.704 40.68,13.704 39.853,11.126 39.03,13.71 36.34,13.704 38.522,15.277"
          />
          <polygon
            fill="#FFED00"
            points="31.699,19.302 30.876,21.881 28.181,21.876 30.364,23.449 29.548,25.988 
			31.699,24.421 33.857,25.988 33.036,23.449 35.226,21.876 32.529,21.876 		"
          />
          <polygon
            fill="#FFED00"
            points="28.715,35.537 30.859,37.116 30.043,34.575 32.233,33.004 29.538,33.004 
			28.715,30.426 27.879,33.01 25.188,33.004 27.372,34.575 26.557,37.116 		"
          />
          <polygon
            id="XMLID_978_"
            fill="#FFED00"
            points="32.527,44.163 31.698,41.592 30.877,44.163 28.18,44.163 30.363,45.742 
			29.547,48.275 31.698,46.702 33.856,48.275 33.035,45.742 35.225,44.163 		"
          />
          <polygon
            id="XMLID_977_"
            fill="#FFED00"
            points="40.692,52.333 39.869,49.762 39.04,52.339 36.344,52.333 38.534,53.906 
			37.718,56.445 39.869,54.871 42.027,56.445 41.206,53.906 43.396,52.333 		"
          />
          <polygon
            id="XMLID_976_"
            fill="#FFED00"
            points="51.823,55.292 51,52.714 50.178,55.292 47.475,55.292 49.665,56.86 
			48.842,59.404 51,57.831 53.158,59.404 52.329,56.86 54.527,55.292 		"
          />
          <polygon
            id="XMLID_975_"
            fill="#FFED00"
            points="62.954,52.333 62.125,49.762 61.295,52.339 58.611,52.333 60.789,53.906 
			59.973,56.445 62.125,54.871 64.282,56.445 63.459,53.906 65.657,52.333 		"
          />
          <polygon
            id="XMLID_974_"
            fill="#FFED00"
            points="71.125,44.163 70.295,41.592 69.474,44.163 66.782,44.163 68.966,45.742 
			68.137,48.275 70.295,46.702 72.453,48.275 71.63,45.742 73.822,44.163 		"
          />
          <polygon
            id="XMLID_972_"
            fill="#FFED00"
            points="76.774,32.973 74.077,32.973 73.261,30.395 72.426,32.973 69.739,32.973 
			71.926,34.545 71.096,37.085 73.261,35.512 75.412,37.085 74.591,34.545 		"
          />
          <polygon
            id="XMLID_971_"
            fill="#FFED00"
            points="68.139,25.949 70.296,24.386 72.454,25.949 71.631,23.411 73.821,21.846 
			71.125,21.846 70.296,19.263 69.476,21.852 66.783,21.846 68.968,23.411 		"
          />
          <polygon
            id="XMLID_969_"
            fill="#FFED00"
            points="62.156,11.126 61.342,13.711 58.638,13.704 60.828,15.277 60.012,17.822 
			62.17,16.242 64.314,17.822 63.499,15.277 65.681,13.704 62.986,13.704 		"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1D1D1C"
              d="M124.794,44.28V33.054h6.853v1.345h-5.281v3.337h4.698v1.312h-4.698v3.888h5.686v1.345H124.794z"
            />
            <path
              fill="#1D1D1C"
              d="M140.266,43.858c-0.346,0.087-0.802,0.178-1.37,0.276c-0.566,0.097-1.223,0.146-1.968,0.146
				c-0.648,0-1.193-0.094-1.636-0.283c-0.443-0.189-0.799-0.456-1.069-0.803c-0.27-0.346-0.464-0.753-0.583-1.223
				c-0.12-0.47-0.178-0.99-0.178-1.563v-4.73h1.507v4.407c0,1.025,0.162,1.76,0.486,2.202c0.324,0.443,0.869,0.665,1.636,0.665
				c0.162,0,0.329-0.006,0.502-0.017c0.173-0.01,0.335-0.024,0.486-0.04c0.15-0.017,0.289-0.033,0.413-0.049
				c0.124-0.016,0.213-0.035,0.267-0.057v-7.111h1.507V43.858z"
            />
            <path
              fill="#1D1D1C"
              d="M145.887,35.678c0.13,0,0.278,0.007,0.445,0.024c0.167,0.016,0.333,0.038,0.495,0.064
				c0.162,0.027,0.31,0.054,0.445,0.081c0.135,0.027,0.235,0.051,0.3,0.072l-0.26,1.313c-0.119-0.043-0.315-0.095-0.591-0.154
				s-0.629-0.089-1.061-0.089c-0.281,0-0.559,0.03-0.834,0.089s-0.457,0.1-0.542,0.122v7.079h-1.507v-8.067
				c0.356-0.129,0.799-0.251,1.329-0.364C144.634,35.735,145.228,35.678,145.887,35.678z"
            />
            <path
              fill="#1D1D1C"
              d="M156.384,39.874c0,0.67-0.097,1.274-0.292,1.814c-0.195,0.541-0.468,1.004-0.818,1.394
				c-0.351,0.389-0.77,0.688-1.255,0.899c-0.486,0.21-1.016,0.315-1.588,0.315s-1.102-0.104-1.587-0.315
				c-0.486-0.211-0.905-0.511-1.255-0.899c-0.351-0.389-0.624-0.853-0.818-1.394c-0.194-0.54-0.292-1.145-0.292-1.814
				c0-0.658,0.098-1.261,0.292-1.806c0.194-0.545,0.467-1.012,0.818-1.401c0.351-0.39,0.77-0.689,1.255-0.899
				s1.015-0.316,1.587-0.316s1.102,0.105,1.588,0.316c0.486,0.21,0.904,0.51,1.255,0.899c0.351,0.389,0.624,0.856,0.818,1.401
				C156.287,38.613,156.384,39.215,156.384,39.874z M154.812,39.874c0-0.95-0.213-1.703-0.639-2.26
				c-0.427-0.556-1.007-0.834-1.742-0.834s-1.315,0.278-1.741,0.834c-0.427,0.557-0.64,1.31-0.64,2.26
				c0,0.951,0.213,1.704,0.64,2.26c0.426,0.556,1.007,0.834,1.741,0.834s1.315-0.279,1.742-0.834
				C154.599,41.578,154.812,40.824,154.812,39.874z"
            />
            <path
              fill="#1D1D1C"
              d="M165.845,40.085c0,0.637-0.084,1.226-0.251,1.765c-0.167,0.541-0.413,1.005-0.737,1.394
				c-0.324,0.389-0.721,0.691-1.191,0.907s-1.007,0.324-1.612,0.324c-0.486,0-0.916-0.065-1.288-0.195
				c-0.373-0.129-0.65-0.253-0.834-0.373v3.37h-1.507V36.115c0.356-0.086,0.802-0.181,1.336-0.283s1.153-0.154,1.855-0.154
				c0.648,0,1.231,0.103,1.75,0.308c0.519,0.205,0.961,0.497,1.329,0.875c0.367,0.377,0.65,0.84,0.851,1.385
				C165.745,38.791,165.845,39.404,165.845,40.085z M164.273,40.085c0-0.982-0.243-1.739-0.729-2.269
				c-0.485-0.529-1.133-0.793-1.943-0.793c-0.454,0-0.808,0.017-1.061,0.049c-0.254,0.032-0.457,0.07-0.607,0.114v5.313
				c0.184,0.152,0.448,0.298,0.793,0.438c0.346,0.141,0.724,0.211,1.134,0.211c0.431,0,0.802-0.079,1.11-0.235
				c0.308-0.156,0.559-0.373,0.753-0.648s0.335-0.599,0.421-0.972C164.23,40.918,164.273,40.517,164.273,40.085z"
            />
            <path
              fill="#1D1D1C"
              d="M167.416,39.89c0-0.745,0.108-1.395,0.324-1.952c0.217-0.556,0.502-1.018,0.859-1.385
				c0.355-0.367,0.766-0.642,1.23-0.826c0.464-0.183,0.939-0.275,1.426-0.275c1.135,0,2.004,0.354,2.607,1.061
				c0.605,0.707,0.908,1.786,0.908,3.232c0,0.064,0,0.149,0,0.251c0,0.103-0.006,0.197-0.016,0.283h-5.768
				c0.064,0.875,0.318,1.539,0.761,1.993c0.442,0.454,1.133,0.681,2.073,0.681c0.529,0,0.975-0.046,1.338-0.139
				c0.361-0.091,0.632-0.18,0.816-0.267l0.211,1.264c-0.184,0.097-0.504,0.2-0.963,0.308c-0.459,0.108-0.98,0.161-1.564,0.161
				c-0.734,0-1.368-0.11-1.902-0.332c-0.535-0.221-0.975-0.527-1.32-0.916s-0.604-0.85-0.77-1.385
				C167.499,41.114,167.416,40.527,167.416,39.89z M173.199,39.064c0.011-0.681-0.158-1.239-0.51-1.677s-0.834-0.656-1.449-0.656
				c-0.346,0-0.65,0.067-0.916,0.202c-0.264,0.135-0.489,0.311-0.672,0.526c-0.184,0.216-0.326,0.464-0.43,0.745
				c-0.104,0.282-0.17,0.568-0.203,0.859H173.199z"
            />
            <path
              fill="#1D1D1C"
              d="M175.484,47.31c-0.13,0-0.297-0.016-0.502-0.049c-0.205-0.032-0.378-0.075-0.519-0.129l0.194-1.231
				c0.108,0.033,0.232,0.059,0.373,0.082c0.14,0.021,0.27,0.032,0.388,0.032c0.519,0,0.888-0.16,1.11-0.479
				c0.221-0.317,0.332-0.786,0.332-1.401v-8.278h1.507v8.263c0,1.08-0.246,1.881-0.737,2.405
				C177.139,47.047,176.423,47.31,175.484,47.31z M177.606,34.333c-0.27,0-0.499-0.089-0.689-0.267
				c-0.188-0.178-0.283-0.418-0.283-0.722c0-0.301,0.095-0.542,0.283-0.721c0.19-0.178,0.419-0.268,0.689-0.268
				c0.269,0,0.5,0.09,0.688,0.268c0.19,0.178,0.285,0.419,0.285,0.721c0,0.303-0.095,0.543-0.285,0.722
				C178.106,34.244,177.875,34.333,177.606,34.333z"
            />
            <path
              fill="#1D1D1C"
              d="M182.837,43c0.616,0,1.072-0.081,1.369-0.243c0.297-0.162,0.445-0.421,0.445-0.777
				c0-0.367-0.146-0.658-0.438-0.875c-0.291-0.216-0.771-0.459-1.441-0.729c-0.324-0.129-0.635-0.262-0.932-0.397
				c-0.297-0.135-0.554-0.294-0.77-0.478c-0.216-0.184-0.389-0.405-0.519-0.665c-0.129-0.259-0.194-0.577-0.194-0.955
				c0-0.746,0.275-1.337,0.826-1.774c0.551-0.438,1.302-0.656,2.252-0.656c0.237,0,0.476,0.014,0.713,0.04
				c0.237,0.028,0.459,0.06,0.664,0.097c0.205,0.039,0.386,0.079,0.543,0.122c0.156,0.043,0.278,0.081,0.364,0.114l-0.275,1.296
				c-0.162-0.086-0.416-0.176-0.762-0.267c-0.346-0.092-0.761-0.139-1.247-0.139c-0.421,0-0.788,0.084-1.102,0.251
				c-0.313,0.167-0.469,0.429-0.469,0.786c0,0.184,0.035,0.346,0.105,0.486c0.07,0.14,0.178,0.267,0.324,0.381
				c0.146,0.113,0.326,0.218,0.542,0.316c0.216,0.097,0.476,0.2,0.778,0.308c0.399,0.151,0.756,0.299,1.068,0.445
				c0.313,0.146,0.581,0.316,0.803,0.511c0.22,0.194,0.39,0.43,0.51,0.705c0.119,0.275,0.179,0.613,0.179,1.013
				c0,0.777-0.289,1.367-0.867,1.766c-0.578,0.4-1.401,0.599-2.471,0.599c-0.745,0-1.328-0.062-1.749-0.186
				c-0.422-0.124-0.708-0.219-0.858-0.283l0.275-1.296c0.172,0.064,0.447,0.162,0.826,0.292C181.709,42.935,182.211,43,182.837,43z"
            />
            <path
              fill="#1D1D1C"
              d="M191.068,39.647c0.324,0.248,0.667,0.556,1.028,0.923c0.362,0.367,0.719,0.764,1.069,1.19
				c0.351,0.427,0.683,0.858,0.996,1.296c0.313,0.438,0.578,0.846,0.794,1.223h-1.766c-0.227-0.377-0.489-0.764-0.786-1.158
				c-0.297-0.394-0.607-0.771-0.931-1.134c-0.324-0.361-0.654-0.694-0.988-0.996c-0.335-0.303-0.654-0.557-0.956-0.762v4.049h-1.507
				V31.968l1.507-0.259v7.678c0.259-0.259,0.545-0.545,0.858-0.857c0.313-0.314,0.621-0.632,0.924-0.957
				c0.302-0.323,0.588-0.633,0.858-0.931c0.27-0.297,0.502-0.559,0.697-0.786h1.766c-0.248,0.271-0.519,0.567-0.81,0.891
				c-0.292,0.324-0.594,0.65-0.907,0.98s-0.629,0.659-0.948,0.988C191.648,39.044,191.348,39.355,191.068,39.647z"
            />
            <path
              fill="#1D1D1C"
              d="M197.142,34.333c-0.27,0-0.499-0.089-0.688-0.267c-0.188-0.178-0.283-0.418-0.283-0.722
				c0-0.301,0.095-0.542,0.283-0.721c0.189-0.178,0.419-0.268,0.688-0.268s0.5,0.09,0.688,0.268
				c0.189,0.178,0.284,0.419,0.284,0.721c0,0.303-0.095,0.543-0.284,0.722C197.642,34.244,197.412,34.333,197.142,34.333z
				 M197.904,44.28h-1.507v-8.424h1.507V44.28z"
            />
            <path
              fill="#1D1D1C"
              d="M204.173,44.28V33.054h6.771v1.345h-5.2v3.386h4.617v1.328h-4.617v5.168H204.173z"
            />
            <path
              fill="#1D1D1C"
              d="M218.996,43.858c-0.346,0.087-0.802,0.178-1.37,0.276c-0.566,0.097-1.222,0.146-1.967,0.146
				c-0.648,0-1.194-0.094-1.637-0.283c-0.442-0.189-0.799-0.456-1.069-0.803c-0.27-0.346-0.464-0.753-0.583-1.223
				c-0.12-0.47-0.178-0.99-0.178-1.563v-4.73h1.506v4.407c0,1.025,0.162,1.76,0.486,2.202c0.324,0.443,0.869,0.665,1.636,0.665
				c0.162,0,0.33-0.006,0.503-0.017c0.172-0.01,0.334-0.024,0.485-0.04c0.151-0.017,0.289-0.033,0.413-0.049
				c0.124-0.016,0.214-0.035,0.268-0.057v-7.111h1.507V43.858z"
            />
            <path
              fill="#1D1D1C"
              d="M221.506,36.099c0.346-0.086,0.805-0.179,1.377-0.275c0.572-0.098,1.231-0.146,1.977-0.146
				c0.67,0,1.225,0.095,1.669,0.283c0.442,0.189,0.796,0.454,1.061,0.794c0.265,0.341,0.451,0.748,0.56,1.223
				c0.107,0.475,0.161,0.999,0.161,1.571v4.73h-1.506v-4.406c0-0.518-0.035-0.961-0.105-1.328s-0.187-0.664-0.349-0.891
				c-0.162-0.227-0.378-0.391-0.647-0.494c-0.271-0.103-0.605-0.154-1.005-0.154c-0.162,0-0.329,0.006-0.502,0.016
				c-0.173,0.011-0.338,0.024-0.494,0.041c-0.158,0.016-0.297,0.035-0.421,0.057c-0.125,0.021-0.214,0.038-0.268,0.048v7.112h-1.507
				V36.099z"
            />
            <path
              fill="#1D1D1C"
              d="M236.167,31.773l1.507-0.259v12.328c-0.346,0.097-0.789,0.194-1.329,0.292
				c-0.54,0.097-1.161,0.146-1.862,0.146c-0.649,0-1.232-0.102-1.75-0.307c-0.519-0.206-0.961-0.497-1.328-0.875
				c-0.367-0.377-0.651-0.839-0.851-1.386c-0.2-0.545-0.3-1.158-0.3-1.838c0-0.648,0.084-1.242,0.251-1.782
				c0.167-0.54,0.413-1.004,0.737-1.394c0.324-0.388,0.721-0.69,1.19-0.907c0.47-0.215,1.007-0.324,1.612-0.324
				c0.485,0,0.915,0.065,1.288,0.195c0.372,0.128,0.65,0.252,0.833,0.372V31.773z M236.167,37.444
				c-0.183-0.151-0.448-0.296-0.793-0.438c-0.346-0.14-0.724-0.211-1.134-0.211c-0.433,0-0.802,0.079-1.109,0.235
				c-0.309,0.157-0.56,0.373-0.754,0.648c-0.194,0.275-0.335,0.603-0.42,0.98c-0.087,0.378-0.13,0.783-0.13,1.215
				c0,0.983,0.243,1.742,0.729,2.276c0.485,0.536,1.134,0.803,1.943,0.803c0.41,0,0.753-0.02,1.029-0.057
				c0.275-0.038,0.488-0.079,0.639-0.122V37.444z"
            />
            <path
              fill="#1D1D1C"
              d="M246.892,43.858c-0.346,0.087-0.802,0.178-1.369,0.276c-0.566,0.097-1.223,0.146-1.968,0.146
				c-0.648,0-1.194-0.094-1.637-0.283c-0.442-0.189-0.798-0.456-1.069-0.803c-0.27-0.346-0.464-0.753-0.583-1.223
				c-0.119-0.47-0.178-0.99-0.178-1.563v-4.73h1.506v4.407c0,1.025,0.162,1.76,0.486,2.202c0.324,0.443,0.869,0.665,1.636,0.665
				c0.162,0,0.33-0.006,0.503-0.017c0.172-0.01,0.334-0.024,0.485-0.04c0.151-0.017,0.289-0.033,0.413-0.049
				c0.124-0.016,0.214-0.035,0.268-0.057v-7.111h1.507V43.858z"
            />
            <path
              fill="#1D1D1C"
              d="M251.363,43c0.616,0,1.072-0.081,1.369-0.243c0.297-0.162,0.445-0.421,0.445-0.777
				c0-0.367-0.146-0.658-0.438-0.875c-0.291-0.216-0.771-0.459-1.441-0.729c-0.324-0.129-0.635-0.262-0.932-0.397
				c-0.297-0.135-0.554-0.294-0.77-0.478c-0.216-0.184-0.389-0.405-0.519-0.665c-0.129-0.259-0.194-0.577-0.194-0.955
				c0-0.746,0.275-1.337,0.826-1.774c0.551-0.438,1.302-0.656,2.252-0.656c0.237,0,0.476,0.014,0.713,0.04
				c0.237,0.028,0.459,0.06,0.664,0.097c0.205,0.039,0.386,0.079,0.543,0.122c0.156,0.043,0.278,0.081,0.364,0.114l-0.275,1.296
				c-0.162-0.086-0.416-0.176-0.762-0.267c-0.345-0.092-0.76-0.139-1.247-0.139c-0.421,0-0.788,0.084-1.102,0.251
				c-0.313,0.167-0.47,0.429-0.47,0.786c0,0.184,0.035,0.346,0.105,0.486c0.07,0.14,0.178,0.267,0.324,0.381
				c0.146,0.113,0.326,0.218,0.542,0.316c0.216,0.097,0.476,0.2,0.778,0.308c0.399,0.151,0.756,0.299,1.068,0.445
				c0.314,0.146,0.582,0.316,0.803,0.511c0.221,0.194,0.391,0.43,0.51,0.705c0.119,0.275,0.179,0.613,0.179,1.013
				c0,0.777-0.289,1.367-0.867,1.766c-0.578,0.4-1.401,0.599-2.471,0.599c-0.745,0-1.328-0.062-1.749-0.186
				c-0.422-0.124-0.708-0.219-0.858-0.283l0.275-1.296c0.172,0.064,0.447,0.162,0.826,0.292C250.235,42.935,250.737,43,251.363,43z"
            />
            <path
              fill="#1D1D1C"
              d="M262.135,36.99c-0.227,0.248-0.526,0.6-0.898,1.053c-0.373,0.454-0.772,0.956-1.199,1.506
				s-0.854,1.128-1.28,1.733s-0.802,1.183-1.125,1.733h4.633v1.263h-6.432v-1.004c0.26-0.475,0.578-0.999,0.956-1.571
				c0.377-0.573,0.774-1.142,1.19-1.709c0.416-0.567,0.821-1.102,1.215-1.604c0.395-0.502,0.737-0.925,1.029-1.271h-4.164v-1.264
				h6.075V36.99z"
            />
            <path
              fill="#1D1D1C"
              d="M130.286,59.432c0.173,0.216,0.392,0.5,0.656,0.85c0.265,0.352,0.537,0.741,0.818,1.167
				c0.28,0.427,0.559,0.873,0.834,1.337c0.275,0.465,0.51,0.913,0.705,1.345h-1.718c-0.216-0.41-0.451-0.826-0.705-1.248
				c-0.253-0.421-0.51-0.823-0.769-1.206c-0.259-0.383-0.516-0.743-0.77-1.078c-0.254-0.334-0.484-0.626-0.688-0.875
				c-0.141,0.011-0.284,0.016-0.43,0.016c-0.146,0-0.294,0-0.445,0h-1.409v4.39h-1.572V53.065c0.443-0.108,0.937-0.181,1.483-0.218
				c0.545-0.038,1.045-0.057,1.499-0.057c1.577,0,2.778,0.297,3.604,0.89c0.826,0.595,1.239,1.48,1.239,2.658
				c0,0.745-0.197,1.382-0.591,1.911C131.633,58.779,131.053,59.173,130.286,59.432z M127.905,54.167
				c-0.669,0-1.183,0.016-1.539,0.049v4.228h1.118c0.54,0,1.026-0.026,1.458-0.081c0.432-0.055,0.796-0.157,1.093-0.308
				s0.526-0.365,0.688-0.64c0.162-0.275,0.243-0.64,0.243-1.093c0-0.421-0.081-0.772-0.243-1.053
				c-0.163-0.281-0.38-0.502-0.656-0.664c-0.276-0.163-0.6-0.275-0.972-0.34C128.723,54.2,128.326,54.167,127.905,54.167z"
            />
            <path
              fill="#1D1D1C"
              d="M142.242,59.724c0,0.669-0.097,1.274-0.292,1.814c-0.195,0.54-0.467,1.005-0.818,1.393
				c-0.351,0.389-0.77,0.689-1.255,0.899c-0.486,0.211-1.016,0.316-1.588,0.316s-1.102-0.105-1.587-0.316
				c-0.486-0.21-0.905-0.511-1.255-0.899c-0.351-0.388-0.624-0.853-0.818-1.393s-0.292-1.145-0.292-1.814
				c0-0.659,0.097-1.261,0.292-1.807c0.194-0.544,0.467-1.012,0.818-1.401c0.351-0.389,0.77-0.689,1.255-0.9
				c0.485-0.21,1.015-0.316,1.587-0.316s1.102,0.105,1.588,0.316c0.486,0.21,0.904,0.511,1.255,0.9s0.624,0.856,0.818,1.401
				C142.145,58.463,142.242,59.065,142.242,59.724z M140.67,59.724c0-0.951-0.213-1.703-0.64-2.261
				c-0.427-0.556-1.007-0.834-1.742-0.834s-1.315,0.278-1.741,0.834c-0.427,0.558-0.64,1.31-0.64,2.261
				c0,0.95,0.213,1.703,0.64,2.259c0.426,0.557,1.007,0.835,1.741,0.835s1.315-0.278,1.742-0.835
				C140.457,61.427,140.67,60.674,140.67,59.724z"
            />
            <path
              fill="#1D1D1C"
              d="M149.548,56.84c-0.227,0.249-0.527,0.6-0.899,1.054c-0.373,0.454-0.773,0.955-1.199,1.506
				c-0.427,0.551-0.854,1.129-1.28,1.733c-0.427,0.605-0.802,1.183-1.126,1.733h4.634v1.264h-6.432v-1.005
				c0.259-0.475,0.578-0.998,0.956-1.571c0.378-0.572,0.775-1.142,1.19-1.708c0.416-0.567,0.821-1.102,1.215-1.604
				c0.394-0.502,0.737-0.926,1.029-1.271h-4.164v-1.264h6.075V56.84z"
            />
            <path
              fill="#1D1D1C"
              d="M158.394,64.13c-0.303-0.778-0.624-1.667-0.964-2.665c-0.34-1-0.651-2.039-0.932-3.119
				c-0.281,1.08-0.589,2.119-0.923,3.119c-0.335,0.998-0.654,1.886-0.956,2.665h-1.312c-0.497-1.113-0.983-2.381-1.458-3.807
				c-0.476-1.426-0.923-2.965-1.345-4.617h1.62c0.108,0.508,0.237,1.058,0.389,1.652c0.151,0.594,0.311,1.188,0.478,1.782
				s0.343,1.167,0.526,1.717c0.184,0.551,0.356,1.032,0.519,1.442c0.184-0.508,0.364-1.045,0.542-1.612
				c0.178-0.567,0.349-1.14,0.51-1.718c0.162-0.577,0.316-1.144,0.462-1.701c0.146-0.556,0.272-1.078,0.381-1.563h1.247
				c0.097,0.485,0.216,1.007,0.356,1.563c0.14,0.557,0.292,1.124,0.454,1.701c0.162,0.578,0.332,1.15,0.51,1.718
				c0.178,0.567,0.359,1.104,0.542,1.612c0.163-0.41,0.333-0.891,0.511-1.442c0.178-0.55,0.354-1.123,0.526-1.717
				s0.334-1.188,0.486-1.782c0.151-0.594,0.281-1.144,0.389-1.652h1.555c-0.421,1.652-0.87,3.191-1.345,4.617
				c-0.476,1.426-0.961,2.694-1.458,3.807H158.394z"
            />
            <path
              fill="#1D1D1C"
              d="M171.255,59.724c0,0.669-0.096,1.274-0.291,1.814c-0.193,0.54-0.467,1.005-0.818,1.393
				c-0.35,0.389-0.77,0.689-1.256,0.899c-0.484,0.211-1.014,0.316-1.587,0.316c-0.572,0-1.102-0.105-1.587-0.316
				c-0.486-0.21-0.905-0.511-1.256-0.899c-0.351-0.388-0.624-0.853-0.818-1.393s-0.292-1.145-0.292-1.814
				c0-0.659,0.097-1.261,0.292-1.807c0.194-0.544,0.467-1.012,0.818-1.401c0.351-0.389,0.77-0.689,1.256-0.9
				s1.015-0.316,1.587-0.316c0.573,0,1.103,0.105,1.587,0.316c0.486,0.21,0.906,0.511,1.256,0.9
				c0.352,0.389,0.625,0.856,0.818,1.401C171.16,58.463,171.255,59.065,171.255,59.724z M169.685,59.724
				c0-0.951-0.213-1.703-0.641-2.261c-0.425-0.556-1.005-0.834-1.741-0.834c-0.734,0-1.315,0.278-1.741,0.834
				c-0.427,0.558-0.64,1.31-0.64,2.261c0,0.95,0.213,1.703,0.64,2.259c0.426,0.557,1.007,0.835,1.741,0.835
				c0.735,0,1.315-0.278,1.741-0.835C169.472,61.427,169.685,60.674,169.685,59.724z"
            />
            <path
              fill="#1D1D1C"
              d="M171.92,67.16c-0.13,0-0.297-0.017-0.502-0.049c-0.205-0.033-0.377-0.076-0.518-0.13l0.194-1.231
				c0.108,0.032,0.232,0.059,0.373,0.081c0.14,0.022,0.27,0.033,0.389,0.033c0.518,0,0.888-0.16,1.109-0.479
				s0.332-0.786,0.332-1.402v-8.277h1.507v8.262c0,1.08-0.246,1.881-0.737,2.405S172.86,67.16,171.92,67.16z M174.042,54.183
				c-0.269,0-0.499-0.089-0.688-0.266c-0.188-0.179-0.283-0.419-0.283-0.722s0.095-0.542,0.283-0.721
				c0.189-0.179,0.419-0.268,0.688-0.268c0.27,0,0.5,0.089,0.689,0.268c0.189,0.178,0.284,0.418,0.284,0.721
				s-0.095,0.543-0.284,0.722C174.542,54.094,174.312,54.183,174.042,54.183z"
            />
            <path
              fill="#1D1D1C"
              d="M184.022,63.709c-0.346,0.086-0.802,0.178-1.369,0.275c-0.566,0.098-1.223,0.146-1.968,0.146
				c-0.648,0-1.194-0.094-1.637-0.284c-0.442-0.188-0.799-0.456-1.069-0.802c-0.27-0.345-0.464-0.753-0.583-1.223
				c-0.119-0.47-0.178-0.991-0.178-1.563v-4.73h1.506v4.407c0,1.026,0.162,1.76,0.486,2.203c0.325,0.443,0.87,0.664,1.636,0.664
				c0.162,0,0.33-0.005,0.503-0.016c0.172-0.011,0.334-0.025,0.485-0.041c0.152-0.016,0.29-0.032,0.414-0.048
				c0.124-0.016,0.214-0.035,0.268-0.057v-7.112h1.507V63.709z"
            />
            <path
              fill="#1D1D1C"
              d="M195.782,59.432c0.173,0.216,0.392,0.5,0.656,0.85c0.265,0.352,0.537,0.741,0.818,1.167
				c0.28,0.427,0.559,0.873,0.834,1.337c0.275,0.465,0.511,0.913,0.705,1.345h-1.718c-0.216-0.41-0.452-0.826-0.704-1.248
				c-0.254-0.421-0.511-0.823-0.77-1.206c-0.26-0.383-0.516-0.743-0.77-1.078c-0.253-0.334-0.483-0.626-0.688-0.875
				c-0.141,0.011-0.284,0.016-0.43,0.016s-0.294,0-0.445,0h-1.409v4.39h-1.571V53.065c0.442-0.108,0.937-0.181,1.482-0.218
				c0.545-0.038,1.044-0.057,1.498-0.057c1.577,0,2.778,0.297,3.604,0.89c0.827,0.595,1.24,1.48,1.24,2.658
				c0,0.745-0.198,1.382-0.591,1.911C197.129,58.779,196.548,59.173,195.782,59.432z M193.401,54.167
				c-0.67,0-1.183,0.016-1.539,0.049v4.228h1.117c0.54,0,1.026-0.026,1.458-0.081c0.433-0.055,0.797-0.157,1.094-0.308
				s0.526-0.365,0.688-0.64c0.162-0.275,0.243-0.64,0.243-1.093c0-0.421-0.081-0.772-0.243-1.053
				c-0.162-0.281-0.381-0.502-0.656-0.664c-0.275-0.163-0.599-0.275-0.972-0.34C194.218,54.2,193.822,54.167,193.401,54.167z"
            />
            <path
              fill="#1D1D1C"
              d="M199.832,59.74c0-0.745,0.108-1.396,0.325-1.952c0.216-0.557,0.502-1.019,0.858-1.386
				c0.356-0.366,0.767-0.642,1.231-0.826c0.463-0.184,0.939-0.276,1.426-0.276c1.134,0,2.003,0.354,2.607,1.062
				c0.605,0.708,0.907,1.785,0.907,3.232c0,0.065,0,0.149,0,0.251s-0.005,0.197-0.016,0.283h-5.768
				c0.064,0.875,0.318,1.54,0.762,1.993c0.442,0.454,1.134,0.68,2.073,0.68c0.529,0,0.975-0.046,1.337-0.138
				c0.361-0.091,0.633-0.181,0.817-0.267l0.211,1.264c-0.184,0.097-0.505,0.199-0.964,0.308s-0.98,0.162-1.563,0.162
				c-0.734,0-1.369-0.111-1.903-0.332c-0.534-0.222-0.975-0.526-1.32-0.916c-0.346-0.388-0.603-0.85-0.77-1.385
				C199.916,60.963,199.832,60.377,199.832,59.74z M205.616,58.914c0.01-0.68-0.159-1.239-0.51-1.677
				c-0.352-0.437-0.835-0.656-1.45-0.656c-0.346,0-0.65,0.067-0.915,0.203c-0.265,0.134-0.49,0.31-0.673,0.526
				c-0.184,0.216-0.326,0.464-0.429,0.745c-0.104,0.281-0.171,0.567-0.203,0.858H205.616z"
            />
            <path
              fill="#1D1D1C"
              d="M214.558,63.288c-0.13,0.086-0.381,0.198-0.753,0.332c-0.373,0.135-0.808,0.202-1.305,0.202
				c-0.507,0-0.985-0.081-1.433-0.243c-0.448-0.162-0.84-0.413-1.175-0.752c-0.334-0.341-0.599-0.764-0.793-1.272
				c-0.194-0.508-0.292-1.112-0.292-1.814c0-0.615,0.092-1.18,0.275-1.693c0.184-0.513,0.452-0.956,0.802-1.329
				c0.351-0.372,0.78-0.664,1.288-0.874c0.508-0.21,1.08-0.316,1.717-0.316c0.702,0,1.315,0.051,1.839,0.154
				c0.524,0.103,0.964,0.198,1.32,0.283v7.518c0,1.296-0.335,2.235-1.004,2.818c-0.67,0.583-1.685,0.875-3.045,0.875
				c-0.529,0-1.028-0.043-1.499-0.13s-0.877-0.189-1.223-0.308l0.275-1.312c0.303,0.118,0.673,0.224,1.109,0.316
				c0.438,0.091,0.894,0.137,1.369,0.137c0.896,0,1.542-0.178,1.937-0.535c0.394-0.356,0.591-0.923,0.591-1.7V63.288z
				 M214.541,57.035c-0.151-0.042-0.353-0.083-0.607-0.122c-0.254-0.038-0.597-0.057-1.028-0.057c-0.81,0-1.434,0.266-1.871,0.794
				s-0.656,1.231-0.656,2.105c0,0.486,0.063,0.902,0.187,1.248c0.124,0.346,0.292,0.632,0.502,0.859
				c0.211,0.228,0.454,0.395,0.729,0.502s0.559,0.162,0.851,0.162c0.399,0,0.767-0.057,1.102-0.17
				c0.333-0.113,0.599-0.246,0.792-0.397V57.035z"
            />
            <path
              fill="#1D1D1C"
              d="M219.304,54.183c-0.27,0-0.499-0.089-0.688-0.266c-0.188-0.179-0.284-0.419-0.284-0.722
				s0.095-0.542,0.284-0.721c0.189-0.179,0.419-0.268,0.688-0.268s0.5,0.089,0.688,0.268c0.189,0.178,0.284,0.418,0.284,0.721
				s-0.095,0.543-0.284,0.722C219.804,54.094,219.574,54.183,219.304,54.183z M220.066,64.13h-1.507v-8.424h1.507V64.13z"
            />
            <path
              fill="#1D1D1C"
              d="M230.012,59.724c0,0.669-0.097,1.274-0.291,1.814s-0.468,1.005-0.818,1.393
				c-0.351,0.389-0.77,0.689-1.256,0.899c-0.485,0.211-1.015,0.316-1.587,0.316c-0.573,0-1.102-0.105-1.588-0.316
				c-0.486-0.21-0.904-0.511-1.256-0.899c-0.351-0.388-0.623-0.853-0.817-1.393s-0.292-1.145-0.292-1.814
				c0-0.659,0.098-1.261,0.292-1.807c0.194-0.544,0.467-1.012,0.817-1.401c0.352-0.389,0.77-0.689,1.256-0.9
				c0.486-0.21,1.015-0.316,1.588-0.316c0.572,0,1.102,0.105,1.587,0.316c0.486,0.21,0.905,0.511,1.256,0.9s0.624,0.856,0.818,1.401
				C229.916,58.463,230.012,59.065,230.012,59.724z M228.441,59.724c0-0.951-0.213-1.703-0.64-2.261
				c-0.427-0.556-1.007-0.834-1.741-0.834c-0.735,0-1.315,0.278-1.742,0.834c-0.427,0.558-0.64,1.31-0.64,2.261
				c0,0.95,0.213,1.703,0.64,2.259c0.427,0.557,1.007,0.835,1.742,0.835c0.734,0,1.314-0.278,1.741-0.835
				C228.228,61.427,228.441,60.674,228.441,59.724z"
            />
            <path
              fill="#1D1D1C"
              d="M232.053,55.949c0.346-0.085,0.805-0.178,1.377-0.275c0.572-0.097,1.231-0.146,1.977-0.146
				c0.67,0,1.226,0.095,1.669,0.283c0.442,0.19,0.796,0.454,1.061,0.794c0.265,0.34,0.451,0.748,0.56,1.223
				c0.107,0.476,0.161,0.999,0.161,1.571v4.731h-1.506v-4.406c0-0.519-0.035-0.962-0.105-1.329s-0.187-0.664-0.349-0.891
				c-0.162-0.226-0.378-0.39-0.648-0.494c-0.27-0.103-0.605-0.154-1.004-0.154c-0.163,0-0.329,0.006-0.502,0.017
				s-0.338,0.024-0.494,0.04c-0.157,0.017-0.297,0.036-0.421,0.058c-0.125,0.022-0.214,0.038-0.268,0.048v7.112h-1.507V55.949z"
            />
            <path
              fill="#1D1D1C"
              d="M243.993,55.333c0.604,0,1.115,0.079,1.531,0.235c0.415,0.157,0.75,0.377,1.004,0.664
				c0.254,0.287,0.435,0.627,0.543,1.021c0.107,0.395,0.162,0.829,0.162,1.304v5.265c-0.13,0.022-0.311,0.051-0.543,0.089
				c-0.233,0.039-0.494,0.073-0.785,0.105c-0.292,0.033-0.608,0.063-0.948,0.089c-0.34,0.027-0.678,0.041-1.013,0.041
				c-0.476,0-0.912-0.049-1.312-0.146c-0.4-0.097-0.746-0.251-1.037-0.462c-0.292-0.21-0.519-0.489-0.681-0.834
				c-0.163-0.345-0.243-0.761-0.243-1.247c0-0.465,0.095-0.864,0.284-1.199c0.188-0.334,0.445-0.604,0.77-0.81
				c0.323-0.206,0.701-0.357,1.134-0.454c0.432-0.098,0.885-0.146,1.36-0.146c0.151,0,0.308,0.009,0.47,0.025
				s0.316,0.038,0.462,0.065c0.146,0.026,0.272,0.051,0.381,0.073c0.107,0.021,0.184,0.039,0.227,0.048v-0.421
				c0-0.248-0.027-0.494-0.081-0.736c-0.054-0.244-0.151-0.459-0.291-0.648c-0.141-0.189-0.332-0.341-0.575-0.454
				c-0.243-0.113-0.56-0.17-0.948-0.17c-0.496,0-0.932,0.036-1.304,0.105c-0.373,0.071-0.65,0.144-0.834,0.219l-0.179-1.247
				c0.194-0.086,0.519-0.17,0.972-0.251C242.972,55.375,243.463,55.333,243.993,55.333z M244.123,62.882
				c0.356,0,0.671-0.007,0.947-0.024c0.275-0.016,0.505-0.045,0.688-0.089v-2.511c-0.107-0.054-0.283-0.1-0.526-0.138
				s-0.537-0.057-0.883-0.057c-0.227,0-0.468,0.017-0.721,0.049c-0.254,0.032-0.486,0.1-0.697,0.203
				c-0.21,0.102-0.386,0.243-0.526,0.42c-0.141,0.179-0.21,0.414-0.21,0.706c0,0.54,0.172,0.915,0.519,1.125
				C243.058,62.778,243.528,62.882,244.123,62.882z"
            />
            <path
              fill="#1D1D1C"
              d="M252.173,64.13c-0.929-0.022-1.588-0.222-1.977-0.6c-0.389-0.377-0.583-0.965-0.583-1.766V51.656
				l1.507-0.259v10.125c0,0.249,0.021,0.454,0.064,0.616c0.043,0.162,0.113,0.291,0.211,0.389c0.097,0.097,0.227,0.17,0.389,0.218
				c0.162,0.049,0.361,0.09,0.6,0.122L252.173,64.13z"
            />
            <path
              fill="#1D1D1C"
              d="M253.988,55.949c0.346-0.085,0.804-0.178,1.377-0.275c0.572-0.097,1.231-0.146,1.977-0.146
				c0.67,0,1.226,0.095,1.669,0.283c0.442,0.19,0.796,0.454,1.061,0.794c0.264,0.34,0.451,0.748,0.56,1.223
				c0.107,0.476,0.161,0.999,0.161,1.571v4.731h-1.505v-4.406c0-0.519-0.036-0.962-0.105-1.329c-0.07-0.367-0.187-0.664-0.349-0.891
				c-0.163-0.226-0.378-0.39-0.647-0.494c-0.271-0.103-0.605-0.154-1.005-0.154c-0.162,0-0.329,0.006-0.502,0.017
				s-0.338,0.024-0.494,0.04c-0.157,0.017-0.297,0.036-0.421,0.058c-0.125,0.022-0.214,0.038-0.268,0.048v7.112h-1.507V55.949z"
            />
            <path
              fill="#1D1D1C"
              d="M262.736,59.74c0-0.745,0.107-1.396,0.324-1.952c0.216-0.557,0.502-1.019,0.858-1.386
				c0.357-0.366,0.767-0.642,1.232-0.826c0.464-0.184,0.939-0.276,1.426-0.276c1.133,0,2.003,0.354,2.607,1.062
				c0.605,0.708,0.907,1.785,0.907,3.232c0,0.065,0,0.149,0,0.251s-0.005,0.197-0.016,0.283h-5.768
				c0.064,0.875,0.318,1.54,0.762,1.993c0.442,0.454,1.134,0.68,2.073,0.68c0.529,0,0.975-0.046,1.337-0.138
				c0.361-0.091,0.634-0.181,0.817-0.267l0.211,1.264c-0.184,0.097-0.505,0.199-0.964,0.308c-0.459,0.108-0.98,0.162-1.563,0.162
				c-0.734,0-1.368-0.111-1.903-0.332c-0.535-0.222-0.975-0.526-1.32-0.916c-0.346-0.388-0.603-0.85-0.77-1.385
				C262.82,60.963,262.736,60.377,262.736,59.74z M268.519,58.914c0.011-0.68-0.159-1.239-0.51-1.677
				c-0.352-0.437-0.835-0.656-1.45-0.656c-0.346,0-0.65,0.067-0.915,0.203c-0.265,0.134-0.489,0.31-0.673,0.526
				c-0.184,0.216-0.326,0.464-0.429,0.745s-0.17,0.567-0.203,0.858H268.519z"
            />
            <path
              fill="#1D1D1C"
              d="M277.461,63.288c-0.13,0.086-0.381,0.198-0.753,0.332c-0.373,0.135-0.807,0.202-1.304,0.202
				c-0.508,0-0.986-0.081-1.434-0.243c-0.448-0.162-0.84-0.413-1.175-0.752c-0.334-0.341-0.599-0.764-0.793-1.272
				c-0.194-0.508-0.292-1.112-0.292-1.814c0-0.615,0.092-1.18,0.276-1.693c0.183-0.513,0.451-0.956,0.802-1.329
				c0.351-0.372,0.78-0.664,1.288-0.874c0.508-0.21,1.08-0.316,1.716-0.316c0.703,0,1.316,0.051,1.839,0.154
				c0.524,0.103,0.964,0.198,1.32,0.283v7.518c0,1.296-0.335,2.235-1.004,2.818c-0.67,0.583-1.686,0.875-3.046,0.875
				c-0.529,0-1.028-0.043-1.498-0.13c-0.471-0.086-0.878-0.189-1.224-0.308l0.275-1.312c0.303,0.118,0.673,0.224,1.109,0.316
				c0.437,0.091,0.893,0.137,1.369,0.137c0.896,0,1.542-0.178,1.937-0.535c0.394-0.356,0.591-0.923,0.591-1.7V63.288z
				 M277.445,57.035c-0.151-0.042-0.354-0.083-0.607-0.122c-0.254-0.038-0.597-0.057-1.028-0.057c-0.81,0-1.434,0.266-1.871,0.794
				c-0.438,0.529-0.656,1.231-0.656,2.105c0,0.486,0.063,0.902,0.187,1.248c0.124,0.346,0.292,0.632,0.502,0.859
				c0.211,0.228,0.454,0.395,0.729,0.502s0.559,0.162,0.851,0.162c0.399,0,0.767-0.057,1.102-0.17
				c0.334-0.113,0.598-0.246,0.793-0.397V57.035z"
            />
            <path
              fill="#1D1D1C"
              d="M288.898,59.724c0,0.669-0.097,1.274-0.291,1.814s-0.468,1.005-0.818,1.393
				c-0.351,0.389-0.77,0.689-1.256,0.899c-0.485,0.211-1.015,0.316-1.587,0.316c-0.573,0-1.102-0.105-1.588-0.316
				c-0.486-0.21-0.904-0.511-1.256-0.899c-0.351-0.388-0.623-0.853-0.817-1.393s-0.292-1.145-0.292-1.814
				c0-0.659,0.098-1.261,0.292-1.807c0.194-0.544,0.466-1.012,0.817-1.401c0.352-0.389,0.77-0.689,1.256-0.9
				s1.015-0.316,1.588-0.316c0.572,0,1.102,0.105,1.587,0.316c0.486,0.21,0.905,0.511,1.256,0.9s0.624,0.856,0.818,1.401
				C288.801,58.463,288.898,59.065,288.898,59.724z M287.327,59.724c0-0.951-0.213-1.703-0.64-2.261
				c-0.427-0.556-1.007-0.834-1.741-0.834c-0.736,0-1.315,0.278-1.742,0.834c-0.427,0.558-0.64,1.31-0.64,2.261
				c0,0.95,0.213,1.703,0.64,2.259c0.427,0.557,1.006,0.835,1.742,0.835c0.734,0,1.314-0.278,1.741-0.835
				C287.114,61.427,287.327,60.674,287.327,59.724z"
            />
          </g>
        </g>
        <g>
          <g>
            <path
              fill="#1D1D1C"
              d="M131.482,21.634c-1.162,0-2.16-0.167-2.995-0.503c-0.835-0.335-1.526-0.806-2.075-1.411
				s-0.953-1.318-1.215-2.136c-0.262-0.818-0.393-1.718-0.393-2.7V4.253h3.118v10.335c0,0.769,0.086,1.428,0.258,1.977
				c0.172,0.549,0.417,0.994,0.736,1.337c0.319,0.344,0.696,0.598,1.129,0.761c0.434,0.164,0.92,0.246,1.46,0.246
				s1.031-0.082,1.473-0.246c0.442-0.163,0.822-0.417,1.142-0.761c0.318-0.343,0.564-0.789,0.736-1.337
				c0.172-0.548,0.258-1.208,0.258-1.977V4.253h3.117v10.63c0,0.981-0.135,1.881-0.405,2.7s-0.679,1.531-1.228,2.136
				s-1.248,1.076-2.099,1.411C133.651,21.466,132.644,21.634,131.482,21.634z"
            />
            <path
              fill="#1D1D1C"
              d="M141.891,9.188c0.573-0.163,1.317-0.319,2.233-0.466c0.917-0.147,1.931-0.221,3.044-0.221
				c1.048,0,1.923,0.143,2.627,0.43c0.704,0.286,1.265,0.687,1.682,1.202c0.417,0.515,0.711,1.138,0.884,1.866
				c0.172,0.728,0.258,1.526,0.258,2.393v7.218h-2.97v-6.751c0-0.687-0.045-1.272-0.135-1.755c-0.09-0.482-0.237-0.875-0.442-1.178
				c-0.205-0.302-0.483-0.523-0.834-0.663c-0.352-0.139-0.782-0.208-1.289-0.208c-0.377,0-0.77,0.025-1.179,0.074
				s-0.711,0.09-0.908,0.122v10.36h-2.97V9.188z"
            />
            <path
              fill="#1D1D1C"
              d="M159.417,5.015c0,0.556-0.18,0.998-0.54,1.325c-0.359,0.328-0.785,0.491-1.276,0.491
				c-0.507,0-0.941-0.163-1.301-0.491c-0.36-0.327-0.54-0.769-0.54-1.325c0-0.573,0.18-1.023,0.54-1.35
				c0.36-0.328,0.794-0.491,1.301-0.491c0.491,0,0.917,0.164,1.276,0.491C159.237,3.992,159.417,4.442,159.417,5.015z
				 M159.099,21.61h-2.971V8.771h2.971V21.61z"
            />
            <path
              fill="#1D1D1C"
              d="M167.002,8.207c0.983,0,1.809,0.122,2.479,0.368c0.672,0.246,1.207,0.589,1.609,1.031
				c0.4,0.442,0.688,0.978,0.859,1.608c0.172,0.629,0.257,1.321,0.257,2.073v7.782c-0.458,0.098-1.15,0.218-2.076,0.357
				c-0.924,0.139-1.967,0.208-3.129,0.208c-0.769,0-1.473-0.074-2.111-0.221c-0.638-0.147-1.183-0.384-1.632-0.712
				c-0.45-0.327-0.802-0.752-1.056-1.276c-0.254-0.524-0.38-1.17-0.38-1.939c0-0.737,0.143-1.358,0.429-1.867
				c0.287-0.506,0.675-0.92,1.166-1.24c0.491-0.318,1.06-0.547,1.706-0.687c0.647-0.139,1.322-0.209,2.025-0.209
				c0.328,0,0.672,0.021,1.031,0.062c0.359,0.041,0.744,0.111,1.154,0.208v-0.49c0-0.344-0.041-0.671-0.123-0.981
				c-0.082-0.312-0.225-0.586-0.43-0.823c-0.205-0.237-0.475-0.421-0.811-0.553c-0.334-0.131-0.756-0.197-1.264-0.197
				c-0.687,0-1.317,0.05-1.89,0.148c-0.573,0.098-1.039,0.213-1.399,0.343l-0.368-2.405c0.376-0.13,0.924-0.262,1.645-0.393
				C165.415,8.272,166.184,8.207,167.002,8.207z M167.249,19.277c0.916,0,1.611-0.049,2.085-0.147v-3.289
				c-0.164-0.05-0.4-0.099-0.712-0.147c-0.311-0.05-0.654-0.075-1.029-0.075c-0.328,0-0.66,0.025-0.995,0.075
				c-0.335,0.048-0.638,0.138-0.908,0.27c-0.27,0.131-0.487,0.315-0.65,0.552c-0.165,0.237-0.246,0.537-0.246,0.896
				c0,0.704,0.221,1.19,0.663,1.461S166.495,19.277,167.249,19.277z"
            />
            <path
              fill="#1D1D1C"
              d="M181.412,21.61V4.598h10.923v2.626h-7.83v4.197h6.972v2.578h-6.972v4.983h8.42v2.627H181.412z"
            />
            <path
              fill="#1D1D1C"
              d="M205.616,20.946c-0.572,0.147-1.317,0.299-2.233,0.454c-0.917,0.156-1.923,0.233-3.02,0.233
				c-1.031,0-1.895-0.147-2.59-0.442c-0.695-0.294-1.252-0.703-1.669-1.227c-0.418-0.524-0.717-1.15-0.897-1.878
				c-0.179-0.728-0.269-1.525-0.269-2.393V8.501h2.97v6.726c0,1.375,0.2,2.356,0.602,2.945s1.101,0.884,2.099,0.884
				c0.36,0,0.74-0.016,1.141-0.049c0.402-0.033,0.701-0.074,0.897-0.123V8.501h2.97V20.946z"
            />
            <path
              fill="#1D1D1C"
              d="M216.564,11.446c-0.245-0.082-0.585-0.168-1.019-0.257c-0.434-0.09-0.937-0.135-1.51-0.135
				c-0.328,0-0.675,0.033-1.043,0.098c-0.369,0.066-0.626,0.123-0.773,0.172V21.61h-2.97V9.384c0.573-0.212,1.289-0.413,2.148-0.601
				s1.812-0.282,2.86-0.282c0.196,0,0.425,0.012,0.687,0.037c0.262,0.024,0.524,0.058,0.786,0.098
				c0.262,0.042,0.516,0.091,0.761,0.148s0.442,0.11,0.589,0.159L216.564,11.446z"
            />
            <path
              fill="#1D1D1C"
              d="M230.605,14.858c0,1.015-0.147,1.939-0.441,2.774c-0.295,0.834-0.712,1.546-1.252,2.135
				c-0.54,0.589-1.19,1.047-1.952,1.375c-0.761,0.327-1.6,0.491-2.516,0.491c-0.917,0-1.751-0.164-2.504-0.491
				c-0.753-0.328-1.399-0.786-1.94-1.375c-0.54-0.589-0.96-1.301-1.263-2.135c-0.304-0.835-0.454-1.759-0.454-2.774
				c0-1.014,0.15-1.935,0.454-2.762c0.303-0.826,0.728-1.534,1.276-2.123c0.548-0.59,1.198-1.043,1.951-1.362
				s1.579-0.479,2.479-0.479c0.899,0,1.73,0.16,2.491,0.479c0.762,0.319,1.412,0.772,1.952,1.362
				c0.54,0.589,0.961,1.297,1.264,2.123C230.454,12.924,230.605,13.844,230.605,14.858z M227.561,14.858
				c0-1.277-0.273-2.286-0.822-3.031c-0.548-0.745-1.313-1.118-2.295-1.118c-0.981,0-1.747,0.373-2.295,1.118
				c-0.549,0.745-0.822,1.754-0.822,3.031c0,1.293,0.273,2.316,0.822,3.069c0.548,0.753,1.313,1.129,2.295,1.129
				c0.982,0,1.747-0.376,2.295-1.129C227.288,17.174,227.561,16.151,227.561,14.858z"
            />
            <path
              fill="#1D1D1C"
              d="M245.088,15.202c0,0.982-0.127,1.883-0.38,2.701c-0.253,0.818-0.618,1.522-1.092,2.111
				c-0.475,0.589-1.068,1.047-1.781,1.375c-0.711,0.327-1.517,0.491-2.418,0.491c-0.605,0-1.162-0.074-1.668-0.221
				c-0.507-0.147-0.925-0.311-1.252-0.491v4.983h-2.97V9.188c0.604-0.163,1.349-0.319,2.233-0.466
				c0.884-0.147,1.816-0.221,2.799-0.221c1.015,0,1.923,0.155,2.725,0.466c0.801,0.311,1.485,0.757,2.05,1.337
				c0.564,0.582,0.998,1.285,1.301,2.111S245.088,14.171,245.088,15.202z M242.044,15.251c0-1.326-0.298-2.356-0.895-3.093
				c-0.598-0.737-1.56-1.104-2.885-1.104c-0.278,0-0.568,0.012-0.871,0.037c-0.304,0.025-0.602,0.078-0.896,0.159v7.34
				c0.262,0.18,0.609,0.348,1.043,0.503c0.434,0.156,0.896,0.234,1.388,0.234c1.08,0,1.869-0.369,2.368-1.105
				C241.794,17.486,242.044,16.495,242.044,15.251z"
            />
            <path
              fill="#1D1D1C"
              d="M247.298,14.957c0-1.13,0.167-2.12,0.503-2.971c0.335-0.85,0.781-1.559,1.338-2.123
				c0.556-0.565,1.194-0.99,1.915-1.277c0.72-0.287,1.456-0.43,2.209-0.43c1.768,0,3.146,0.549,4.137,1.645
				c0.989,1.097,1.484,2.733,1.484,4.91c0,0.164-0.004,0.348-0.012,0.553c-0.009,0.205-0.021,0.389-0.038,0.551h-8.468
				c0.081,1.032,0.445,1.83,1.091,2.394c0.647,0.564,1.584,0.847,2.812,0.847c0.72,0,1.378-0.065,1.976-0.197
				c0.598-0.13,1.068-0.27,1.411-0.417l0.394,2.431c-0.164,0.082-0.39,0.168-0.676,0.257c-0.286,0.09-0.613,0.172-0.981,0.246
				s-0.766,0.135-1.19,0.184c-0.426,0.05-0.859,0.074-1.302,0.074c-1.129,0-2.11-0.167-2.945-0.503
				c-0.835-0.335-1.521-0.802-2.062-1.399c-0.541-0.597-0.941-1.301-1.203-2.111S247.298,15.922,247.298,14.957z M255.915,13.631
				c0-0.409-0.058-0.798-0.172-1.166c-0.116-0.369-0.283-0.688-0.504-0.958c-0.221-0.27-0.49-0.482-0.81-0.638
				c-0.319-0.155-0.699-0.233-1.142-0.233c-0.458,0-0.859,0.086-1.204,0.257c-0.343,0.172-0.633,0.397-0.871,0.676
				c-0.237,0.278-0.422,0.597-0.553,0.957c-0.131,0.36-0.221,0.729-0.27,1.105H255.915z"
            />
            <path
              fill="#1D1D1C"
              d="M260.235,26.175c-0.213,0-0.511-0.021-0.896-0.062c-0.385-0.041-0.725-0.119-1.019-0.233l0.393-2.43
				c0.36,0.115,0.794,0.172,1.301,0.172c0.688,0,1.17-0.201,1.448-0.602c0.278-0.402,0.418-1.003,0.418-1.804V8.771h2.97v12.495
				c0,1.669-0.396,2.905-1.19,3.707C262.866,25.773,261.724,26.175,260.235,26.175z M265.169,5.015c0,0.556-0.18,0.998-0.54,1.325
				c-0.36,0.328-0.785,0.491-1.276,0.491c-0.508,0-0.941-0.163-1.301-0.491c-0.36-0.327-0.54-0.769-0.54-1.325
				c0-0.573,0.18-1.023,0.54-1.35c0.359-0.328,0.793-0.491,1.301-0.491c0.491,0,0.916,0.164,1.276,0.491
				C264.989,3.992,265.169,4.442,265.169,5.015z"
            />
            <path
              fill="#1D1D1C"
              d="M271.699,19.179c0.785,0,1.357-0.094,1.717-0.282c0.361-0.188,0.541-0.511,0.541-0.97
				c0-0.426-0.192-0.778-0.577-1.056c-0.385-0.277-1.019-0.58-1.902-0.908c-0.54-0.196-1.035-0.405-1.484-0.626
				c-0.452-0.221-0.839-0.479-1.167-0.773c-0.327-0.294-0.585-0.65-0.772-1.068c-0.188-0.417-0.282-0.929-0.282-1.535
				c0-1.177,0.433-2.106,1.301-2.785c0.867-0.679,2.045-1.02,3.535-1.02c0.752,0,1.473,0.07,2.16,0.209
				c0.688,0.139,1.202,0.274,1.546,0.405l-0.54,2.405c-0.327-0.147-0.744-0.283-1.252-0.405c-0.507-0.123-1.097-0.184-1.768-0.184
				c-0.605,0-1.096,0.102-1.473,0.306c-0.376,0.206-0.564,0.521-0.564,0.946c0,0.213,0.037,0.401,0.11,0.564
				c0.074,0.164,0.2,0.315,0.38,0.454c0.18,0.14,0.417,0.279,0.712,0.418c0.294,0.139,0.655,0.282,1.08,0.429
				c0.704,0.262,1.301,0.52,1.792,0.773c0.492,0.254,0.896,0.54,1.215,0.86c0.319,0.319,0.553,0.684,0.7,1.092
				c0.146,0.409,0.221,0.9,0.221,1.473c0,1.227-0.454,2.157-1.362,2.787c-0.908,0.629-2.206,0.945-3.892,0.945
				c-1.129,0-2.037-0.094-2.725-0.283c-0.688-0.188-1.17-0.339-1.449-0.454l0.516-2.48c0.441,0.181,0.97,0.352,1.583,0.516
				C270.213,19.098,270.913,19.179,271.699,19.179z"
            />
            <path
              fill="#1D1D1C"
              d="M282.524,13.778c0.376-0.393,0.777-0.813,1.203-1.264c0.425-0.451,0.843-0.9,1.252-1.351
				c0.409-0.45,0.798-0.884,1.166-1.301c0.368-0.417,0.683-0.782,0.945-1.092h3.51c-0.818,0.916-1.682,1.865-2.59,2.848
				c-0.908,0.981-1.82,1.939-2.736,2.872c0.49,0.409,1.006,0.905,1.546,1.485c0.54,0.582,1.063,1.199,1.571,1.854
				c0.507,0.655,0.981,1.31,1.424,1.964c0.441,0.655,0.809,1.26,1.104,1.817h-3.436c-0.295-0.507-0.635-1.04-1.019-1.596
				c-0.385-0.556-0.798-1.096-1.24-1.62c-0.441-0.523-0.892-1.019-1.35-1.485c-0.458-0.467-0.908-0.863-1.35-1.19v5.892h-2.97V5.088
				l2.97-0.49V13.778z"
            />
            <path
              fill="#1D1D1C"
              d="M297.204,8.207c0.982,0,1.809,0.122,2.479,0.368s1.207,0.589,1.608,1.031
				c0.401,0.442,0.688,0.978,0.86,1.608c0.172,0.629,0.258,1.321,0.258,2.073v7.782c-0.459,0.098-1.15,0.218-2.075,0.357
				s-1.968,0.208-3.13,0.208c-0.769,0-1.473-0.074-2.11-0.221c-0.639-0.147-1.183-0.384-1.633-0.712
				c-0.45-0.327-0.801-0.752-1.055-1.276c-0.254-0.524-0.381-1.17-0.381-1.939c0-0.737,0.143-1.358,0.43-1.867
				c0.286-0.506,0.676-0.92,1.166-1.24c0.491-0.318,1.06-0.547,1.706-0.687c0.646-0.139,1.322-0.209,2.025-0.209
				c0.327,0,0.671,0.021,1.031,0.062c0.359,0.041,0.744,0.111,1.153,0.208v-0.49c0-0.344-0.041-0.671-0.122-0.981
				c-0.082-0.312-0.226-0.586-0.43-0.823c-0.205-0.237-0.475-0.421-0.811-0.553c-0.335-0.131-0.757-0.197-1.264-0.197
				c-0.688,0-1.317,0.05-1.891,0.148c-0.573,0.098-1.039,0.213-1.399,0.343l-0.369-2.405c0.377-0.13,0.925-0.262,1.646-0.393
				C295.617,8.272,296.385,8.207,297.204,8.207z M297.45,19.277c0.916,0,1.611-0.049,2.086-0.147v-3.289
				c-0.163-0.05-0.4-0.099-0.712-0.147c-0.311-0.05-0.655-0.075-1.03-0.075c-0.328,0-0.659,0.025-0.994,0.075
				c-0.336,0.048-0.639,0.138-0.908,0.27c-0.271,0.131-0.487,0.315-0.651,0.552c-0.163,0.237-0.245,0.537-0.245,0.896
				c0,0.704,0.221,1.19,0.663,1.461C296.099,19.143,296.697,19.277,297.45,19.277z"
            />
          </g>
        </g>
      </g>
    </Ue>
  )
}

export const LogoPl = () => {
  return (
    <Pl x="0px" y="0px" viewBox="0 0 430 98">
      <g id="znak">
        <g>
          <g>
            <path
              fill="#1D1D1B"
              d="M189.16,24.651c-0.966,0-1.856,0.04-2.674,0.107v8.11h1.99c1.109,0,2.085-0.061,2.929-0.184
				c0.848-0.116,1.554-0.335,2.12-0.65c0.564-0.31,0.995-0.733,1.286-1.264c0.288-0.531,0.432-1.208,0.432-2.029
				c0-0.769-0.144-1.423-0.432-1.952c-0.292-0.53-0.707-0.953-1.253-1.269c-0.535-0.314-1.183-0.539-1.936-0.668
				C190.873,24.72,190.051,24.651,189.16,24.651 M188.836,20.709c3.624,0,6.389,0.661,8.308,1.991
				c1.917,1.321,2.879,3.35,2.879,6.08c0,3.402-1.676,5.705-5.035,6.911c0.461,0.558,0.978,1.229,1.561,2.029
				c0.575,0.796,1.167,1.661,1.772,2.587c0.602,0.929,1.177,1.883,1.735,2.863c0.558,0.977,1.05,1.933,1.491,2.876h-5.106
				c-0.461-0.87-0.957-1.746-1.488-2.625c-0.53-0.881-1.064-1.737-1.61-2.571c-0.544-0.832-1.079-1.614-1.609-2.354
				c-0.531-0.733-1.026-1.392-1.486-1.972c-0.336,0.028-0.625,0.037-0.867,0.037h-0.688h-2.207v9.485h-4.567V21.322
				c1.114-0.241,2.297-0.407,3.554-0.492C186.725,20.746,187.847,20.709,188.836,20.709"
            />
            <path
              fill="#1D1D1B"
              d="M218.412,30.19c-0.487,0.502-1.113,1.219-1.9,2.134c-0.784,0.919-1.622,1.936-2.52,3.06
				c-0.894,1.123-1.788,2.294-2.692,3.513c-0.906,1.218-1.721,2.383-2.443,3.493h9.767v3.654H203.57v-2.711
				c0.53-0.97,1.197-2.016,1.992-3.153c0.796-1.133,1.618-2.274,2.459-3.421c0.847-1.144,1.685-2.236,2.518-3.273
				c0.83-1.038,1.576-1.946,2.225-2.713h-8.724v-3.66h14.372V30.19z"
            />
            <path
              fill="#1D1D1B"
              d="M233.723,34.714c0-0.601-0.085-1.175-0.255-1.718c-0.164-0.542-0.413-1.015-0.736-1.412
				c-0.331-0.398-0.728-0.713-1.2-0.944c-0.468-0.228-1.028-0.343-1.683-0.343c-0.677,0-1.269,0.126-1.775,0.379
				c-0.504,0.256-0.935,0.586-1.282,0.997c-0.352,0.412-0.623,0.882-0.813,1.41c-0.196,0.531-0.324,1.076-0.404,1.63H233.723z
				 M221.017,36.668c0-1.661,0.243-3.122,0.742-4.378c0.492-1.259,1.152-2.299,1.969-3.133c0.827-0.83,1.761-1.461,2.828-1.883
				c1.059-0.419,2.149-0.63,3.257-0.63c2.61,0,4.641,0.807,6.103,2.425c1.464,1.619,2.188,4.031,2.188,7.237
				c0,0.247-0.006,0.516-0.02,0.816c-0.012,0.303-0.034,0.576-0.054,0.814h-12.488c0.116,1.523,0.658,2.699,1.608,3.527
				c0.954,0.835,2.339,1.255,4.148,1.255c1.059,0,2.03-0.101,2.911-0.292c0.881-0.192,1.577-0.4,2.081-0.616l0.584,3.582
				c-0.244,0.124-0.575,0.25-1,0.384c-0.422,0.133-0.906,0.255-1.441,0.362c-0.547,0.108-1.13,0.2-1.762,0.269
				c-0.625,0.074-1.269,0.109-1.914,0.109c-1.671,0-3.115-0.247-4.347-0.741c-1.23-0.5-2.243-1.182-3.044-2.067
				c-0.796-0.876-1.385-1.914-1.771-3.113C221.209,39.403,221.017,38.096,221.017,36.668"
            />
            <path
              fill="#1D1D1B"
              d="M241.686,36.597c0-1.398,0.219-2.71,0.657-3.927c0.431-1.219,1.051-2.276,1.857-3.168
				c0.811-0.891,1.799-1.59,2.954-2.099c1.164-0.507,2.458-0.762,3.911-0.762c1.781,0,3.475,0.323,5.065,0.979l-0.938,3.582
				c-0.51-0.216-1.084-0.396-1.719-0.538c-0.643-0.147-1.322-0.219-2.047-0.219c-1.717,0-3.019,0.532-3.911,1.61
				c-0.892,1.075-1.337,2.591-1.337,4.542c0,1.884,0.42,3.375,1.27,4.47c0.838,1.1,2.262,1.65,4.268,1.65
				c0.75,0,1.48-0.078,2.208-0.218c0.726-0.143,1.352-0.327,1.884-0.542l0.614,3.619c-0.484,0.238-1.215,0.458-2.191,0.65
				c-0.975,0.195-1.982,0.289-3.022,0.289c-1.619,0-3.024-0.247-4.219-0.738c-1.192-0.5-2.181-1.184-2.965-2.067
				c-0.785-0.878-1.374-1.93-1.756-3.15C241.882,39.342,241.686,38.02,241.686,36.597"
            />
            <path
              fill="#1D1D1B"
              d="M273.072,30.19c-0.479,0.502-1.121,1.219-1.9,2.134c-0.784,0.919-1.625,1.936-2.515,3.06
				c-0.892,1.123-1.792,2.294-2.698,3.513c-0.903,1.218-1.722,2.383-2.447,3.493h9.775v3.654h-15.054v-2.711
				c0.529-0.97,1.189-2.016,1.988-3.153c0.795-1.133,1.613-2.274,2.463-3.421c0.845-1.144,1.683-2.236,2.513-3.273
				c0.838-1.038,1.577-1.946,2.229-2.713H258.7v-3.66h14.372V30.19z"
            />
            <path
              fill="#1D1D1B"
              d="M289.904,36.668c0-1.954-0.441-3.476-1.322-4.558c-0.881-1.086-2.302-1.63-4.253-1.63
				c-0.408,0-0.839,0.017-1.283,0.051c-0.447,0.04-0.887,0.116-1.322,0.239v10.825c0.388,0.26,0.903,0.51,1.537,0.736
				c0.638,0.232,1.323,0.347,2.042,0.347c1.592,0,2.767-0.541,3.497-1.629C289.533,39.965,289.904,38.503,289.904,36.668
				 M294.39,36.597c0,1.445-0.185,2.776-0.562,3.983c-0.376,1.206-0.906,2.244-1.605,3.112c-0.702,0.87-1.577,1.545-2.628,2.026
				c-1.051,0.484-2.24,0.727-3.565,0.727c-0.897,0-1.719-0.108-2.461-0.327c-0.747-0.218-1.361-0.458-1.846-0.728v7.35h-4.381
				V27.728c0.889-0.239,1.987-0.47,3.293-0.684c1.306-0.22,2.679-0.332,4.126-0.332c1.498,0,2.835,0.229,4.019,0.691
				c1.181,0.458,2.189,1.116,3.024,1.971c0.835,0.856,1.473,1.897,1.917,3.113C294.168,33.707,294.39,35.076,294.39,36.597"
            />
            <path
              fill="#1D1D1B"
              d="M311.547,36.56c0-1.884-0.396-3.371-1.209-4.467c-0.813-1.099-1.937-1.648-3.387-1.648
				c-1.447,0-2.577,0.549-3.384,1.648c-0.805,1.096-1.215,2.583-1.215,4.467c0,1.904,0.41,3.415,1.215,4.527
				c0.807,1.107,1.937,1.664,3.384,1.664c1.45,0,2.574-0.557,3.387-1.664C311.151,39.975,311.547,38.464,311.547,36.56
				 M316.042,36.56c0,1.5-0.218,2.859-0.653,4.089c-0.434,1.233-1.051,2.282-1.847,3.152c-0.796,0.866-1.756,1.544-2.874,2.029
				c-1.125,0.48-2.362,0.722-3.716,0.722c-1.345,0-2.583-0.242-3.689-0.722c-1.11-0.484-2.064-1.163-2.86-2.029
				c-0.796-0.87-1.416-1.918-1.86-3.152c-0.453-1.23-0.674-2.59-0.674-4.089c0-1.497,0.221-2.853,0.674-4.072
				c0.444-1.217,1.07-2.264,1.877-3.132c0.811-0.868,1.765-1.538,2.886-2.006c1.102-0.472,2.322-0.707,3.647-0.707
				c1.334,0,2.552,0.235,3.679,0.707c1.118,0.469,2.078,1.138,2.874,2.006s1.422,1.915,1.869,3.132
				C315.818,33.707,316.042,35.063,316.042,36.56"
            />
            <path
              fill="#1D1D1B"
              d="M325.302,42.894c1.164,0,2.008-0.139,2.537-0.415c0.529-0.276,0.802-0.753,0.802-1.43
				c0-0.627-0.286-1.146-0.855-1.554c-0.566-0.415-1.507-0.861-2.807-1.341c-0.796-0.289-1.526-0.596-2.188-0.927
				c-0.666-0.323-1.238-0.702-1.722-1.136c-0.481-0.436-0.864-0.963-1.139-1.577c-0.277-0.616-0.414-1.368-0.414-2.26
				c0-1.737,0.635-3.105,1.915-4.109c1.277-1.004,3.019-1.504,5.216-1.504c1.107,0,2.166,0.102,3.181,0.307
				c1.02,0.209,1.778,0.405,2.288,0.601l-0.802,3.544c-0.484-0.215-1.099-0.417-1.847-0.596c-0.741-0.18-1.611-0.273-2.604-0.273
				c-0.893,0-1.617,0.154-2.17,0.453c-0.563,0.3-0.838,0.771-0.838,1.394c0,0.314,0.054,0.592,0.164,0.833
				c0.11,0.244,0.298,0.467,0.561,0.674c0.267,0.203,0.612,0.408,1.054,0.615c0.428,0.205,0.966,0.415,1.589,0.631
				c1.042,0.387,1.923,0.768,2.645,1.14c0.726,0.374,1.325,0.797,1.793,1.265c0.464,0.473,0.815,1.011,1.027,1.616
				c0.224,0.603,0.329,1.325,0.329,2.17c0,1.811-0.669,3.18-2.011,4.109c-1.337,0.93-3.251,1.392-5.734,1.392
				c-1.665,0-3.008-0.139-4.021-0.415c-1.014-0.276-1.725-0.5-2.138-0.669l0.765-3.659c0.651,0.269,1.427,0.522,2.333,0.761
				C323.113,42.777,324.147,42.894,325.302,42.894"
            />
            <path
              fill="#1D1D1B"
              d="M349.669,36.668c0-1.954-0.447-3.476-1.322-4.558c-0.883-1.086-2.296-1.63-4.253-1.63
				c-0.414,0-0.844,0.017-1.292,0.051c-0.444,0.04-0.877,0.116-1.313,0.239v10.825c0.382,0.26,0.895,0.51,1.538,0.736
				c0.643,0.232,1.322,0.347,2.041,0.347c1.598,0,2.753-0.541,3.498-1.629C349.302,39.965,349.669,38.503,349.669,36.668
				 M354.156,36.597c0,1.445-0.19,2.776-0.561,3.983c-0.371,1.206-0.912,2.244-1.614,3.112c-0.694,0.87-1.575,1.545-2.62,2.026
				c-1.05,0.484-2.239,0.727-3.564,0.727c-0.898,0-1.714-0.108-2.467-0.327c-0.748-0.218-1.362-0.458-1.841-0.728v7.35h-4.381
				V27.728c0.895-0.239,1.988-0.47,3.293-0.684c1.306-0.22,2.68-0.332,4.126-0.332c1.498,0,2.841,0.229,4.024,0.691
				c1.175,0.458,2.184,1.116,3.016,1.971c0.833,0.856,1.47,1.897,1.92,3.113C353.935,33.707,354.156,35.076,354.156,36.597"
            />
            <path
              fill="#1D1D1B"
              d="M371.319,36.56c0-1.884-0.41-3.371-1.215-4.467c-0.807-1.099-1.937-1.648-3.387-1.648
				c-1.447,0-2.577,0.549-3.384,1.648c-0.81,1.096-1.209,2.583-1.209,4.467c0,1.904,0.399,3.415,1.209,4.527
				c0.807,1.107,1.937,1.664,3.384,1.664c1.45,0,2.58-0.557,3.387-1.664C370.909,39.975,371.319,38.464,371.319,36.56
				 M375.805,36.56c0,1.5-0.216,2.859-0.651,4.089c-0.437,1.233-1.051,2.282-1.847,3.152c-0.802,0.866-1.756,1.544-2.88,2.029
				c-1.118,0.48-2.356,0.722-3.71,0.722c-1.351,0-2.582-0.242-3.689-0.722c-1.11-0.484-2.064-1.163-2.86-2.029
				c-0.796-0.87-1.416-1.918-1.86-3.152c-0.453-1.23-0.674-2.59-0.674-4.089c0-1.497,0.221-2.853,0.674-4.072
				c0.444-1.217,1.07-2.264,1.88-3.132c0.808-0.868,1.768-1.538,2.872-2.006c1.112-0.472,2.333-0.707,3.658-0.707
				c1.322,0,2.552,0.235,3.679,0.707c1.119,0.469,2.079,1.138,2.874,2.006c0.802,0.868,1.416,1.915,1.861,3.132
				C375.584,33.707,375.805,35.063,375.805,36.56"
            />
            <path
              fill="#1D1D1B"
              d="M386.915,46.406c-1.306-0.024-2.382-0.168-3.237-0.435c-0.857-0.265-1.543-0.642-2.045-1.141
				c-0.507-0.493-0.866-1.107-1.067-1.845c-0.206-0.737-0.309-1.576-0.309-2.515V21.93l4.378-0.724v18.432
				c0,0.53,0.043,0.98,0.125,1.341c0.088,0.363,0.235,0.67,0.459,0.923c0.209,0.252,0.507,0.445,0.88,0.578
				c0.377,0.133,0.853,0.237,1.431,0.306L386.915,46.406z"
            />
            <path
              fill="#1D1D1B"
              d="M395.492,46.044h-4.375V27.112h4.375V46.044z M395.964,21.577c0,0.818-0.266,1.468-0.795,1.952
				c-0.53,0.484-1.161,0.724-1.884,0.724c-0.742,0-1.385-0.239-1.914-0.724c-0.529-0.484-0.796-1.134-0.796-1.952
				c0-0.847,0.267-1.511,0.796-1.991c0.529-0.485,1.172-0.726,1.914-0.726c0.723,0,1.354,0.241,1.884,0.726
				C395.699,20.066,395.964,20.73,395.964,21.577"
            />
            <path
              fill="#1D1D1B"
              d="M400.85,21.93l4.383-0.722v5.904h6.731v3.66h-6.731v7.709c0,1.517,0.238,2.604,0.723,3.254
				c0.481,0.655,1.306,0.981,2.461,0.981c0.801,0,1.506-0.085,2.121-0.254c0.617-0.17,1.104-0.326,1.464-0.474l0.728,3.48
				c-0.51,0.214-1.178,0.438-1.996,0.668c-0.821,0.228-1.787,0.343-2.895,0.343c-1.354,0-2.483-0.181-3.381-0.542
				c-0.906-0.361-1.629-0.889-2.158-1.576c-0.535-0.688-0.903-1.522-1.118-2.499c-0.224-0.976-0.331-2.09-0.331-3.346V21.93z"
            />
            <path
              fill="#1D1D1B"
              d="M422.862,42.971c1.351,0,2.376-0.078,3.078-0.221v-4.85c-0.247-0.074-0.595-0.143-1.051-0.219
				c-0.462-0.068-0.966-0.108-1.523-0.108c-0.476,0-0.966,0.04-1.464,0.108c-0.493,0.076-0.94,0.207-1.343,0.399
				c-0.394,0.192-0.711,0.464-0.954,0.815c-0.244,0.348-0.362,0.792-0.362,1.321c0,1.038,0.325,1.753,0.977,2.154
				C420.868,42.771,421.754,42.971,422.862,42.971 M422.497,26.642c1.452,0,2.667,0.181,3.658,0.542
				c0.988,0.365,1.778,0.869,2.373,1.518c0.589,0.654,1.014,1.446,1.269,2.377c0.249,0.929,0.374,1.944,0.374,3.059v11.473
				c-0.671,0.143-1.69,0.32-3.056,0.526c-1.367,0.203-2.902,0.308-4.618,0.308c-1.13,0-2.167-0.109-3.109-0.326
				c-0.94-0.221-1.745-0.566-2.407-1.051c-0.666-0.484-1.184-1.11-1.555-1.883c-0.377-0.773-0.563-1.725-0.563-2.858
				c0-1.085,0.212-2.002,0.631-2.753c0.425-0.746,0.997-1.356,1.725-1.829c0.723-0.469,1.558-0.807,2.515-1.011
				c0.955-0.204,1.946-0.309,2.988-0.309c0.476,0,0.985,0.028,1.515,0.091c0.532,0.061,1.099,0.159,1.705,0.307v-0.724
				c0-0.508-0.06-0.992-0.182-1.445c-0.122-0.462-0.328-0.865-0.637-1.213c-0.304-0.35-0.7-0.623-1.192-0.816
				c-0.493-0.192-1.116-0.292-1.863-0.292c-1.014,0-1.942,0.078-2.79,0.219c-0.844,0.14-1.528,0.31-2.059,0.506l-0.546-3.547
				c0.552-0.194,1.361-0.384,2.424-0.577C420.16,26.739,421.29,26.642,422.497,26.642"
            />
            <path
              fill="#1D1D1B"
              d="M189.336,58.211c-1.152,0-2.104,0.04-2.859,0.109v8.548h2.215c2.191,0,3.88-0.308,5.066-0.928
				c1.183-0.612,1.774-1.776,1.774-3.492c0-0.817-0.162-1.507-0.475-2.061c-0.307-0.558-0.748-0.991-1.299-1.306
				c-0.555-0.313-1.215-0.533-1.979-0.67C191.021,58.283,190.205,58.211,189.336,58.211 M189.017,54.27
				c3.642,0,6.432,0.66,8.376,1.99c1.947,1.327,2.912,3.401,2.912,6.228c0,1.472-0.259,2.732-0.773,3.782
				c-0.524,1.049-1.276,1.905-2.261,2.566c-0.992,0.669-2.198,1.153-3.621,1.469c-1.429,0.315-3.053,0.469-4.889,0.469h-2.283v8.832
				h-4.56V54.884c1.061-0.237,2.238-0.405,3.529-0.487C186.74,54.312,187.929,54.27,189.017,54.27"
            />
            <path
              fill="#1D1D1B"
              d="M216.237,70.125c0-1.883-0.402-3.371-1.216-4.47c-0.806-1.101-1.937-1.646-3.385-1.646
				c-1.443,0-2.572,0.545-3.378,1.646c-0.809,1.099-1.215,2.587-1.215,4.47c0,1.904,0.406,3.415,1.215,4.523
				c0.807,1.11,1.936,1.667,3.378,1.667c1.449,0,2.58-0.557,3.385-1.667C215.834,73.541,216.237,72.03,216.237,70.125
				 M220.725,70.125c0,1.494-0.218,2.858-0.652,4.088c-0.43,1.234-1.045,2.279-1.845,3.15c-0.791,0.868-1.754,1.543-2.877,2.027
				c-1.122,0.484-2.36,0.724-3.715,0.724c-1.352,0-2.582-0.239-3.686-0.724c-1.118-0.484-2.069-1.159-2.859-2.027
				c-0.802-0.871-1.425-1.916-1.87-3.15c-0.447-1.229-0.669-2.594-0.669-4.088c0-1.497,0.223-2.857,0.669-4.071
				c0.444-1.223,1.075-2.264,1.883-3.132c0.81-0.873,1.769-1.543,2.882-2.011c1.106-0.47,2.321-0.706,3.649-0.706
				c1.333,0,2.552,0.236,3.677,0.706c1.122,0.468,2.081,1.138,2.876,2.011c0.796,0.868,1.424,1.909,1.868,3.132
				C220.501,67.268,220.725,68.628,220.725,70.125"
            />
            <path
              fill="#1D1D1B"
              d="M231.84,79.969c-1.306-0.024-2.382-0.17-3.242-0.431c-0.855-0.271-1.538-0.647-2.048-1.143
				c-0.504-0.497-0.858-1.11-1.066-1.85c-0.201-0.733-0.307-1.571-0.307-2.513V55.538l4.381-0.726v18.392
				c0,0.529,0.048,0.976,0.127,1.338c0.085,0.361,0.235,0.668,0.453,0.922c0.221,0.252,0.513,0.444,0.89,0.58
				c0.368,0.135,0.844,0.234,1.427,0.31L231.84,79.969z"
            />
            <path
              fill="#1D1D1B"
              d="M240.744,76.458c1.161,0,2.005-0.139,2.534-0.415c0.529-0.28,0.796-0.756,0.796-1.428
				c0-0.629-0.28-1.15-0.85-1.558c-0.566-0.412-1.507-0.857-2.807-1.339c-0.796-0.291-1.526-0.6-2.188-0.926
				c-0.669-0.327-1.237-0.702-1.722-1.141c-0.487-0.43-0.864-0.959-1.139-1.572c-0.277-0.62-0.416-1.373-0.416-2.264
				c0-1.739,0.637-3.106,1.917-4.106c1.283-1.002,3.019-1.507,5.211-1.507c1.112,0,2.175,0.105,3.193,0.308
				c1.012,0.208,1.771,0.408,2.28,0.601l-0.802,3.547c-0.484-0.22-1.099-0.418-1.841-0.597c-0.753-0.184-1.622-0.271-2.61-0.271
				c-0.89,0-1.617,0.149-2.175,0.449c-0.553,0.303-0.827,0.768-0.827,1.395c0,0.314,0.054,0.592,0.158,0.833
				c0.11,0.239,0.292,0.463,0.563,0.671c0.261,0.205,0.615,0.41,1.045,0.613c0.439,0.209,0.972,0.415,1.595,0.636
				c1.042,0.387,1.917,0.763,2.647,1.141c0.723,0.373,1.317,0.796,1.796,1.265c0.467,0.472,0.81,1.01,1.027,1.61
				c0.213,0.607,0.323,1.329,0.323,2.175c0,1.808-0.668,3.176-2.011,4.105c-1.337,0.931-3.251,1.396-5.74,1.396
				c-1.659,0-2.996-0.144-4.01-0.419c-1.017-0.276-1.73-0.5-2.138-0.67l0.759-3.654c0.654,0.265,1.433,0.52,2.328,0.76
				C238.551,76.339,239.58,76.458,240.744,76.458"
            />
            <path
              fill="#1D1D1B"
              d="M256.921,68.059c0.559-0.574,1.147-1.197,1.778-1.861c0.626-0.666,1.246-1.327,1.847-1.991
				c0.598-0.665,1.175-1.304,1.722-1.92c0.538-0.613,1.006-1.152,1.391-1.609h5.174c-1.201,1.348-2.467,2.748-3.804,4.201
				c-1.336,1.445-2.685,2.859-4.032,4.234c0.725,0.601,1.487,1.331,2.277,2.188c0.795,0.856,1.568,1.769,2.316,2.733
				c0.744,0.968,1.444,1.93,2.09,2.896c0.654,0.966,1.189,1.857,1.628,2.676h-5.074c-0.431-0.746-0.929-1.529-1.498-2.35
				c-0.566-0.821-1.176-1.617-1.83-2.394c-0.651-0.769-1.316-1.498-1.987-2.186c-0.68-0.69-1.337-1.271-1.997-1.758v8.687h-4.381
				V55.538l4.381-0.726V68.059z"
            />
            <path
              fill="#1D1D1B"
              d="M279.152,76.533c1.351,0,2.37-0.074,3.081-0.217v-4.85c-0.249-0.078-0.595-0.147-1.057-0.22
				s-0.966-0.111-1.523-0.111c-0.481,0-0.966,0.039-1.458,0.111c-0.499,0.072-0.943,0.203-1.349,0.396
				c-0.396,0.195-0.711,0.468-0.957,0.814c-0.24,0.353-0.362,0.791-0.362,1.322c0,1.036,0.328,1.756,0.985,2.153
				C277.155,76.332,278.042,76.533,279.152,76.533 M278.79,60.204c1.449,0,2.664,0.185,3.655,0.546
				c0.991,0.362,1.778,0.869,2.37,1.518c0.59,0.654,1.014,1.442,1.269,2.373c0.255,0.929,0.383,1.948,0.383,3.059v11.478
				c-0.674,0.143-1.696,0.319-3.062,0.522c-1.37,0.206-2.902,0.309-4.615,0.309c-1.13,0-2.17-0.109-3.115-0.323
				c-0.938-0.22-1.744-0.57-2.407-1.055c-0.66-0.484-1.184-1.107-1.555-1.883c-0.377-0.77-0.558-1.721-0.558-2.859
				c0-1.083,0.209-1.998,0.628-2.752c0.423-0.745,1-1.353,1.723-1.825c0.722-0.474,1.56-0.808,2.515-1.011
				c0.957-0.209,1.942-0.31,2.981-0.31c0.481,0,0.991,0.028,1.523,0.091c0.529,0.057,1.096,0.16,1.708,0.308v-0.727
				c0-0.51-0.063-0.989-0.188-1.445c-0.121-0.463-0.328-0.861-0.631-1.215c-0.307-0.346-0.7-0.623-1.198-0.815
				c-0.493-0.191-1.113-0.288-1.863-0.288c-1.011,0-1.939,0.074-2.789,0.22c-0.844,0.141-1.526,0.312-2.059,0.506l-0.547-3.55
				c0.558-0.191,1.365-0.386,2.43-0.578C276.45,60.304,277.58,60.204,278.79,60.204"
            />
          </g>
          <g>
            <rect
              fill="#FFFFFF"
              stroke="#1D1D1C"
              strokeWidth="0.2835"
              strokeMiterlimit="10"
              width="148.672"
              height="49.518"
            />
            <rect y="49.518" fill="#B51F2B" width="148.672" height="49.459" />
          </g>
        </g>
      </g>
      <g id="ramka">
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="-33.328"
          y1="-70.73"
          x2="-33.328"
          y2="-34.73"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="-70.745"
          y1="-33.313"
          x2="-34.746"
          y2="-33.313"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="-70.631"
          y1="132.312"
          x2="-34.632"
          y2="132.312"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="-33.328"
          y1="169.73"
          x2="-33.328"
          y2="133.73"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="461.744"
          y1="-70.73"
          x2="461.744"
          y2="-34.73"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="501.57"
          y1="-32.279"
          x2="465.57"
          y2="-32.279"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="501.57"
          y1="132.312"
          x2="465.57"
          y2="132.312"
        />
        <line
          fill="none"
          stroke="#000000"
          strokeWidth="0.3"
          x1="463.229"
          y1="169.73"
          x2="463.229"
          y2="133.73"
        />
      </g>
    </Pl>
  )
}

export const LogoFe = () => {
  return (
    <Fe x="0px" y="0px" viewBox="0 0 209.828 90.708">
      <polygon
        fill="#164194"
        points="45.361,90.708 0,82.204 0,14.173 45.361,0 "
      />
      <path
        fill="#FFED00"
        d="M0.007,65.854V48.19c2.284,0.622,4.825,0.913,6.5-0.098c3.664-2.185,9.213-8.875,9.213-8.875
	s-2.926,7.215-1.822,9.865c1.166,2.812,8.099,2.963,8.099,2.963s-7.837,4.764-11.484,7.923c-3.751,3.221-6.355,15.063-6.355,15.063
	S2.339,67.442,0.007,65.854"
      />
      <path
        fill="#E30613"
        d="M45.361,34.693c-1.569,0.988-3.109,1.766-4.266,1.992c-3.645,0.703-7.546-3.484-7.546-3.484
	s0.917,6.122-1.376,9.893c-2.414,3.975-10.176,8.951-10.176,8.951s8.58-1.66,10.248,1.001c1.774,2.854,2.011,12.794,2.011,12.794
	s4.372-11.934,8.338-13.56c0.772-0.328,1.737-0.632,2.767-0.925V34.693z"
      />
      <path
        fill="#FFFFFF"
        d="M15.72,39.218c0,0,4.384-6.869,4.119-9.314c-0.26-2.32-4.807-3.157-4.807-3.157s5.953-1.463,8.549-3.797
	c2.505-2.255,4.694-6.933,4.694-6.933s0.554,3.871,2.781,4.426c2.298,0.578,8.437-1.9,8.437-1.9s-4.846,4.586-6.748,7.087
	c-1.987,2.607,0.804,7.572,0.804,7.572s-5.144-1.79-8.021-1.252C22.695,32.487,15.72,39.218,15.72,39.218"
      />
      <g>
        <polygon
          fill="#1D1D1B"
          points="68.027,34.014 68.027,17.001 78.779,17.001 78.779,19.628 71.12,19.628 71.12,23.974 77.92,23.974 
	77.92,26.6 71.12,26.6 71.12,34.014 "
        />
        <path
          fill="yellow"
          d="M91.249,33.351c-0.573,0.147-1.317,0.299-2.234,0.454c-0.917,0.156-1.922,0.233-3.019,0.233
	c-1.031,0-1.895-0.147-2.59-0.442c-0.696-0.294-1.252-0.703-1.669-1.227c-0.418-0.524-0.717-1.15-0.897-1.878
	c-0.18-0.729-0.27-1.526-0.27-2.394v-7.192h2.971v6.726c0,1.375,0.2,2.357,0.601,2.946s1.101,0.883,2.099,0.883
	c0.36,0,0.74-0.017,1.142-0.048c0.4-0.033,0.698-0.074,0.896-0.123V20.905h2.97V33.351z"
        />
        <path
          fill="#1D1D1B"
          d="M94.883,21.592c0.573-0.163,1.317-0.318,2.234-0.466c0.916-0.148,1.931-0.221,3.044-0.221
	c1.047,0,1.923,0.143,2.626,0.429c0.704,0.287,1.265,0.688,1.682,1.203s0.712,1.138,0.884,1.866
	c0.172,0.728,0.258,1.525,0.258,2.393v7.217h-2.971v-6.75c0-0.688-0.045-1.272-0.135-1.755c-0.09-0.482-0.237-0.876-0.442-1.179
	c-0.204-0.302-0.483-0.523-0.834-0.663c-0.352-0.138-0.782-0.208-1.289-0.208c-0.377,0-0.769,0.024-1.179,0.073
	c-0.409,0.049-0.711,0.09-0.908,0.123v10.358h-2.97V21.592z"
        />
        <path
          fill="#1D1D1B"
          d="M119.947,33.326c-0.59,0.181-1.332,0.344-2.223,0.491c-0.893,0.146-1.828,0.221-2.811,0.221
	c-1.014,0-1.924-0.155-2.725-0.466c-0.802-0.312-1.486-0.758-2.05-1.338c-0.565-0.581-0.999-1.285-1.301-2.111
	c-0.304-0.827-0.454-1.756-0.454-2.787c0-1.014,0.126-1.935,0.38-2.761c0.254-0.827,0.626-1.535,1.117-2.124
	s1.088-1.043,1.792-1.362c0.703-0.319,1.514-0.479,2.431-0.479c0.621,0,1.169,0.073,1.644,0.221
	c0.476,0.146,0.885,0.311,1.229,0.491v-3.83l2.971-0.49V33.326z M111.428,27.263c0,1.31,0.312,2.336,0.933,3.081
	c0.621,0.744,1.48,1.116,2.577,1.116c0.474,0,0.88-0.021,1.216-0.062c0.336-0.039,0.609-0.086,0.822-0.135V23.9
	c-0.263-0.181-0.611-0.349-1.044-0.505c-0.435-0.154-0.896-0.232-1.387-0.232c-1.08,0-1.87,0.369-2.369,1.104
	C111.677,25.004,111.428,26.003,111.428,27.263"
        />
        <path
          fill="#1D1D1B"
          d="M134.111,33.351c-0.572,0.147-1.318,0.299-2.233,0.454c-0.917,0.156-1.923,0.233-3.021,0.233
	c-1.031,0-1.894-0.147-2.59-0.442c-0.695-0.294-1.252-0.703-1.669-1.227c-0.418-0.524-0.717-1.15-0.896-1.878
	c-0.181-0.729-0.271-1.526-0.271-2.394v-7.192h2.97v6.726c0,1.375,0.2,2.357,0.603,2.946c0.401,0.589,1.1,0.883,2.098,0.883
	c0.361,0,0.74-0.017,1.142-0.048c0.401-0.033,0.701-0.074,0.896-0.123V20.905h2.971V33.351z"
        />
        <path
          fill="#1D1D1B"
          d="M140.958,31.583c0.785,0,1.357-0.094,1.719-0.282c0.359-0.188,0.54-0.511,0.54-0.97
	c0-0.425-0.192-0.776-0.577-1.056c-0.385-0.277-1.02-0.58-1.902-0.908c-0.541-0.196-1.035-0.405-1.484-0.626
	c-0.451-0.221-0.84-0.478-1.167-0.773c-0.327-0.295-0.585-0.65-0.773-1.067s-0.282-0.928-0.282-1.534
	c0-1.179,0.434-2.107,1.301-2.786s2.046-1.019,3.535-1.019c0.752,0,1.474,0.069,2.16,0.209c0.688,0.139,1.203,0.273,1.547,0.404
	l-0.541,2.405c-0.326-0.146-0.744-0.282-1.252-0.405c-0.506-0.123-1.096-0.184-1.767-0.184c-0.606,0-1.097,0.103-1.474,0.307
	c-0.375,0.205-0.563,0.521-0.563,0.945c0,0.213,0.037,0.401,0.11,0.565c0.074,0.164,0.199,0.314,0.381,0.454
	c0.179,0.139,0.417,0.277,0.712,0.417c0.294,0.139,0.653,0.281,1.08,0.43c0.703,0.262,1.301,0.519,1.792,0.772
	c0.49,0.254,0.895,0.54,1.215,0.859c0.319,0.319,0.553,0.684,0.7,1.092c0.146,0.41,0.221,0.901,0.221,1.475
	c0,1.227-0.454,2.155-1.362,2.785c-0.908,0.631-2.207,0.945-3.892,0.945c-1.129,0-2.038-0.094-2.726-0.283
	c-0.688-0.188-1.17-0.339-1.447-0.454l0.516-2.479c0.441,0.18,0.969,0.352,1.582,0.515
	C139.474,31.501,140.173,31.583,140.958,31.583"
        />
        <path
          fill="#1D1D1B"
          d="M157.798,23.262c-0.328,0.344-0.758,0.827-1.289,1.448c-0.532,0.621-1.102,1.313-1.706,2.073
	c-0.606,0.763-1.216,1.557-1.829,2.382c-0.613,0.826-1.166,1.616-1.656,2.368h6.627v2.48h-10.211v-1.841
	c0.359-0.655,0.809-1.367,1.35-2.137c0.539-0.769,1.097-1.542,1.669-2.318c0.573-0.778,1.142-1.519,1.706-2.223
	c0.566-0.703,1.068-1.316,1.51-1.841h-5.916v-2.479h9.746V23.262z"
        />
        <path
          fill="#1D1D1B"
          d="M159.419,27.361c0-1.13,0.167-2.119,0.503-2.971c0.335-0.852,0.78-1.56,1.338-2.123
	c0.556-0.565,1.194-0.99,1.915-1.277c0.719-0.286,1.456-0.429,2.209-0.429c1.768,0,3.145,0.548,4.136,1.644
	c0.99,1.098,1.485,2.734,1.485,4.91c0,0.164-0.004,0.349-0.012,0.552c-0.01,0.205-0.021,0.39-0.037,0.554h-8.469
	c0.08,1.03,0.444,1.829,1.092,2.393c0.646,0.565,1.584,0.847,2.811,0.847c0.721,0,1.378-0.064,1.977-0.195
	c0.598-0.131,1.067-0.271,1.41-0.417l0.395,2.43c-0.164,0.082-0.391,0.168-0.676,0.258c-0.287,0.089-0.613,0.171-0.981,0.245
	c-0.369,0.073-0.767,0.135-1.19,0.185c-0.427,0.049-0.859,0.072-1.303,0.072c-1.129,0-2.109-0.167-2.945-0.502
	c-0.834-0.336-1.521-0.802-2.062-1.399s-0.94-1.302-1.203-2.111C159.55,29.215,159.419,28.327,159.419,27.361 M168.035,26.035
	c0-0.409-0.058-0.798-0.172-1.166c-0.115-0.368-0.281-0.688-0.504-0.958c-0.22-0.27-0.49-0.481-0.809-0.638
	c-0.32-0.155-0.7-0.233-1.143-0.233c-0.457,0-0.858,0.086-1.203,0.259c-0.344,0.172-0.633,0.396-0.871,0.675
	c-0.236,0.277-0.422,0.598-0.553,0.957s-0.221,0.729-0.269,1.104H168.035z"
        />
        <polygon
          fill="#1D1D1B"
          points="68.027,56.69 68.027,39.679 78.951,39.679 78.951,42.306 71.12,42.306 71.12,46.503 78.091,46.503 
	78.091,49.081 71.12,49.081 71.12,54.064 79.54,54.064 79.54,56.69 "
        />
        <path
          fill="#1D1D1B"
          d="M92.231,56.028c-0.573,0.146-1.317,0.299-2.234,0.454s-1.923,0.232-3.019,0.232
	c-1.031,0-1.895-0.146-2.59-0.441c-0.696-0.294-1.252-0.703-1.669-1.228c-0.418-0.523-0.717-1.149-0.897-1.878
	c-0.18-0.728-0.27-1.525-0.27-2.393v-7.193h2.971v6.727c0,1.375,0.2,2.356,0.601,2.945s1.101,0.883,2.099,0.883
	c0.36,0,0.74-0.016,1.142-0.048c0.401-0.033,0.699-0.074,0.896-0.123V43.582h2.97V56.028z"
        />
        <path
          fill="#1D1D1B"
          d="M103.18,46.527c-0.246-0.082-0.585-0.167-1.019-0.257s-0.937-0.136-1.509-0.136
	c-0.328,0-0.676,0.034-1.044,0.099c-0.368,0.065-0.626,0.122-0.773,0.171V56.69h-2.97V44.466c0.572-0.212,1.289-0.413,2.148-0.602
	c0.859-0.188,1.812-0.282,2.859-0.282c0.197,0,0.426,0.013,0.688,0.036c0.262,0.025,0.523,0.059,0.786,0.099
	c0.261,0.041,0.515,0.091,0.76,0.147c0.246,0.058,0.442,0.11,0.59,0.159L103.18,46.527z"
        />
        <path
          fill="#1D1D1B"
          d="M117.22,49.94c0,1.015-0.146,1.938-0.441,2.773c-0.295,0.834-0.711,1.546-1.252,2.136
	c-0.539,0.59-1.189,1.048-1.951,1.375s-1.6,0.49-2.516,0.49c-0.917,0-1.752-0.163-2.505-0.49s-1.399-0.785-1.938-1.375
	c-0.541-0.59-0.961-1.302-1.265-2.136c-0.303-0.835-0.454-1.759-0.454-2.773c0-1.016,0.151-1.936,0.454-2.763
	c0.303-0.825,0.728-1.533,1.277-2.122c0.548-0.591,1.198-1.045,1.95-1.363c0.754-0.319,1.58-0.479,2.48-0.479
	c0.898,0,1.73,0.16,2.49,0.479c0.762,0.318,1.412,0.772,1.953,1.363c0.539,0.589,0.961,1.297,1.264,2.122
	C117.07,48.005,117.22,48.925,117.22,49.94 M114.177,49.94c0-1.277-0.273-2.287-0.822-3.032c-0.549-0.744-1.313-1.117-2.295-1.117
	s-1.748,0.373-2.296,1.117c-0.548,0.745-0.821,1.755-0.821,3.032c0,1.293,0.273,2.315,0.821,3.067
	c0.548,0.753,1.313,1.13,2.296,1.13s1.746-0.377,2.295-1.13C113.904,52.256,114.177,51.233,114.177,49.94"
        />
        <path
          fill="#1D1D1B"
          d="M131.705,50.284c0,0.981-0.127,1.882-0.379,2.699c-0.254,0.819-0.619,1.522-1.094,2.111
	c-0.474,0.59-1.068,1.048-1.779,1.375s-1.519,0.491-2.418,0.491c-0.605,0-1.162-0.074-1.67-0.222
	c-0.506-0.146-0.924-0.311-1.252-0.49v4.982h-2.97V44.27c0.604-0.164,1.351-0.318,2.234-0.467c0.884-0.147,1.815-0.221,2.799-0.221
	c1.015,0,1.923,0.155,2.724,0.467c0.803,0.311,1.486,0.757,2.051,1.338s0.998,1.284,1.301,2.111
	C131.554,48.323,131.705,49.253,131.705,50.284 M128.662,50.333c0-1.326-0.299-2.356-0.896-3.094
	c-0.598-0.736-1.559-1.104-2.884-1.104c-0.279,0-0.569,0.012-0.871,0.037c-0.304,0.024-0.603,0.078-0.897,0.16v7.339
	c0.262,0.181,0.61,0.349,1.043,0.504c0.435,0.156,0.897,0.232,1.389,0.232c1.08,0,1.869-0.367,2.367-1.104
	C128.412,52.566,128.662,51.577,128.662,50.333"
        />
        <path
          fill="#1D1D1B"
          d="M133.916,50.039c0-1.131,0.166-2.119,0.502-2.972c0.336-0.851,0.781-1.559,1.339-2.123
	c0.555-0.564,1.194-0.989,1.915-1.276c0.719-0.286,1.456-0.43,2.209-0.43c1.767,0,3.145,0.548,4.136,1.645
	c0.99,1.097,1.485,2.733,1.485,4.91c0,0.164-0.004,0.348-0.012,0.552c-0.01,0.205-0.021,0.389-0.037,0.553h-8.469
	c0.08,1.031,0.444,1.829,1.092,2.394c0.646,0.564,1.584,0.847,2.811,0.847c0.72,0,1.378-0.065,1.977-0.196
	c0.598-0.131,1.067-0.271,1.41-0.417l0.395,2.431c-0.164,0.081-0.391,0.168-0.676,0.258c-0.287,0.089-0.613,0.171-0.981,0.245
	c-0.369,0.072-0.767,0.135-1.19,0.184c-0.427,0.049-0.859,0.073-1.303,0.073c-1.129,0-2.109-0.167-2.945-0.502
	c-0.834-0.336-1.521-0.803-2.062-1.4c-0.541-0.597-0.94-1.301-1.203-2.111C134.046,51.892,133.916,51.005,133.916,50.039
	 M142.531,48.713c0-0.409-0.058-0.798-0.172-1.166c-0.115-0.368-0.281-0.688-0.504-0.958c-0.22-0.271-0.49-0.482-0.809-0.639
	c-0.32-0.154-0.7-0.232-1.143-0.232c-0.457,0-0.858,0.086-1.203,0.258c-0.344,0.172-0.633,0.397-0.871,0.675
	c-0.236,0.278-0.422,0.599-0.553,0.957c-0.131,0.36-0.221,0.729-0.269,1.105H142.531z"
        />
        <path
          fill="#1D1D1B"
          d="M146.85,61.257c-0.212,0-0.51-0.021-0.896-0.062c-0.385-0.041-0.725-0.119-1.019-0.232l0.394-2.431
	c0.359,0.114,0.793,0.171,1.301,0.171c0.688,0,1.17-0.2,1.447-0.601c0.278-0.402,0.418-1.003,0.418-1.804V43.853h2.971v12.494
	c0,1.67-0.396,2.904-1.191,3.708C149.481,60.855,148.34,61.257,146.85,61.257 M151.785,40.097c0,0.556-0.181,0.998-0.539,1.325
	c-0.36,0.327-0.785,0.49-1.276,0.49c-0.508,0-0.942-0.163-1.302-0.49c-0.36-0.327-0.539-0.77-0.539-1.325
	c0-0.573,0.179-1.023,0.539-1.352c0.359-0.327,0.794-0.489,1.302-0.489c0.491,0,0.916,0.162,1.276,0.489
	C151.604,39.073,151.785,39.523,151.785,40.097"
        />
        <path
          fill="#1D1D1B"
          d="M158.316,54.261c0.784,0,1.356-0.094,1.717-0.282s0.541-0.511,0.541-0.97c0-0.425-0.193-0.777-0.578-1.057
	c-0.385-0.276-1.019-0.58-1.902-0.907c-0.54-0.196-1.035-0.405-1.484-0.626c-0.451-0.222-0.839-0.479-1.166-0.773
	c-0.328-0.295-0.586-0.651-0.773-1.068c-0.188-0.417-0.282-0.928-0.282-1.533c0-1.18,0.433-2.107,1.301-2.786
	c0.867-0.68,2.046-1.02,3.535-1.02c0.752,0,1.474,0.07,2.16,0.209c0.688,0.14,1.203,0.274,1.546,0.405l-0.54,2.405
	c-0.327-0.147-0.744-0.282-1.252-0.405c-0.507-0.123-1.097-0.184-1.767-0.184c-0.606,0-1.096,0.103-1.474,0.307
	c-0.376,0.205-0.563,0.52-0.563,0.945c0,0.213,0.037,0.4,0.109,0.564c0.074,0.164,0.2,0.315,0.381,0.454
	c0.18,0.139,0.418,0.278,0.713,0.417c0.293,0.139,0.654,0.282,1.08,0.43c0.703,0.263,1.301,0.52,1.791,0.773
	c0.492,0.254,0.896,0.54,1.215,0.859c0.32,0.318,0.553,0.684,0.701,1.092c0.146,0.41,0.221,0.9,0.221,1.474
	c0,1.228-0.455,2.156-1.363,2.786s-2.206,0.945-3.891,0.945c-1.129,0-2.038-0.095-2.725-0.283c-0.688-0.188-1.171-0.339-1.449-0.454
	l0.516-2.479c0.441,0.181,0.97,0.353,1.583,0.516C156.83,54.179,157.529,54.261,158.316,54.261"
        />
        <path
          fill="#1D1D1B"
          d="M169.139,48.859c0.376-0.393,0.776-0.813,1.203-1.264c0.425-0.45,0.843-0.9,1.252-1.35
	c0.409-0.45,0.798-0.885,1.166-1.302c0.368-0.418,0.683-0.78,0.944-1.092h3.511c-0.818,0.916-1.681,1.865-2.591,2.847
	c-0.907,0.982-1.819,1.939-2.735,2.872c0.49,0.41,1.006,0.905,1.546,1.485c0.54,0.581,1.063,1.199,1.571,1.854
	c0.507,0.655,0.981,1.31,1.424,1.965c0.44,0.653,0.81,1.26,1.104,1.815h-3.437c-0.295-0.507-0.635-1.039-1.019-1.596
	c-0.385-0.556-0.797-1.096-1.24-1.62c-0.441-0.523-0.891-1.018-1.35-1.485c-0.459-0.466-0.908-0.862-1.351-1.19v5.892h-2.97V40.17
	l2.97-0.491V48.859z"
        />
        <path
          fill="#1D1D1B"
          d="M182.863,40.097c0,0.556-0.182,0.998-0.541,1.324c-0.36,0.328-0.785,0.492-1.276,0.492
	c-0.509,0-0.941-0.164-1.3-0.492c-0.361-0.326-0.541-0.769-0.541-1.324c0-0.573,0.18-1.023,0.541-1.351
	c0.358-0.328,0.791-0.491,1.3-0.491c0.491,0,0.916,0.163,1.276,0.491C182.681,39.073,182.863,39.523,182.863,40.097 M182.542,56.69
	h-2.971V43.853h2.971V56.69z"
        />
        <path
          fill="#1D1D1B"
          d="M185.464,50.039c0-1.131,0.167-2.119,0.503-2.972c0.335-0.851,0.78-1.559,1.338-2.123
	c0.556-0.564,1.194-0.989,1.915-1.276c0.719-0.286,1.456-0.43,2.209-0.43c1.768,0,3.145,0.548,4.137,1.645
	c0.989,1.097,1.484,2.733,1.484,4.91c0,0.164-0.004,0.348-0.012,0.552c-0.01,0.205-0.021,0.389-0.037,0.553h-8.469
	c0.08,1.031,0.444,1.829,1.092,2.394c0.646,0.564,1.584,0.847,2.811,0.847c0.721,0,1.378-0.065,1.977-0.196
	c0.598-0.131,1.067-0.271,1.41-0.417l0.395,2.431c-0.164,0.081-0.391,0.168-0.676,0.258c-0.286,0.089-0.613,0.171-0.981,0.245
	c-0.368,0.072-0.767,0.135-1.19,0.184c-0.426,0.049-0.859,0.073-1.303,0.073c-1.129,0-2.109-0.167-2.945-0.502
	c-0.834-0.336-1.521-0.803-2.062-1.4c-0.541-0.597-0.94-1.301-1.203-2.111C185.595,51.892,185.464,51.005,185.464,50.039
	 M194.08,48.713c0-0.409-0.058-0.798-0.172-1.166c-0.115-0.368-0.281-0.688-0.504-0.958c-0.22-0.271-0.49-0.482-0.809-0.639
	c-0.32-0.154-0.7-0.232-1.143-0.232c-0.457,0-0.858,0.086-1.203,0.258c-0.344,0.172-0.633,0.397-0.87,0.675
	c-0.237,0.278-0.423,0.599-0.554,0.957c-0.131,0.36-0.221,0.729-0.269,1.105H194.08z"
        />
        <rect
          x="68.046"
          y="68.157"
          fillRule="evenodd"
          clipRule="evenodd"
          fill="#1D1D1B"
          width="1.572"
          height="11.228"
        />
        <path
          fill="#1D1D1B"
          d="M72.226,71.202c0.346-0.086,0.805-0.178,1.377-0.274c0.572-0.099,1.231-0.146,1.977-0.146
	c0.669,0,1.225,0.095,1.668,0.283c0.443,0.19,0.796,0.454,1.061,0.794c0.265,0.341,0.451,0.748,0.559,1.223
	c0.108,0.476,0.162,0.999,0.162,1.572v4.73h-1.507v-4.406c0-0.52-0.035-0.962-0.105-1.329c-0.07-0.367-0.186-0.664-0.348-0.891
	s-0.378-0.392-0.648-0.494c-0.27-0.104-0.605-0.154-1.005-0.154c-0.162,0-0.329,0.006-0.502,0.016
	c-0.172,0.013-0.337,0.025-0.494,0.041c-0.156,0.017-0.297,0.035-0.421,0.058c-0.124,0.021-0.213,0.038-0.267,0.048v7.112h-1.507
	V71.202z"
        />
        <path
          fill="#1D1D1B"
          d="M82.853,70.766h3.191v1.263h-3.191v3.888c0,0.423,0.032,0.771,0.097,1.045
	c0.065,0.276,0.162,0.492,0.292,0.648c0.129,0.157,0.291,0.267,0.486,0.332c0.194,0.065,0.421,0.097,0.68,0.097
	c0.454,0,0.818-0.05,1.093-0.152c0.276-0.104,0.468-0.177,0.576-0.22l0.291,1.247c-0.151,0.076-0.416,0.17-0.794,0.284
	c-0.378,0.113-0.809,0.17-1.296,0.17c-0.572,0-1.045-0.073-1.417-0.219c-0.373-0.146-0.673-0.364-0.899-0.656
	c-0.227-0.291-0.386-0.649-0.478-1.077c-0.092-0.427-0.138-0.921-0.138-1.482v-7.517l1.507-0.259V70.766z"
        />
        <path
          fill="#1D1D1B"
          d="M87.243,74.993c0-0.745,0.108-1.396,0.324-1.952c0.216-0.556,0.502-1.018,0.858-1.385
	c0.357-0.367,0.767-0.642,1.232-0.826c0.464-0.184,0.939-0.275,1.425-0.275c1.134,0,2.004,0.354,2.609,1.061
	c0.604,0.708,0.907,1.785,0.907,3.232V75.1c0,0.102-0.006,0.195-0.016,0.282h-5.768c0.065,0.875,0.318,1.539,0.762,1.993
	c0.442,0.453,1.134,0.681,2.073,0.681c0.529,0,0.975-0.047,1.337-0.139c0.361-0.091,0.634-0.181,0.818-0.267l0.21,1.264
	c-0.183,0.097-0.505,0.199-0.964,0.308s-0.98,0.161-1.563,0.161c-0.734,0-1.369-0.11-1.903-0.332
	c-0.535-0.221-0.975-0.525-1.321-0.915c-0.345-0.389-0.602-0.85-0.769-1.385C87.326,76.216,87.243,75.63,87.243,74.993
	 M93.026,74.167c0.011-0.681-0.159-1.239-0.51-1.677s-0.834-0.656-1.45-0.656c-0.346,0-0.651,0.068-0.915,0.203
	c-0.265,0.135-0.489,0.311-0.672,0.526c-0.184,0.216-0.328,0.464-0.43,0.745c-0.103,0.28-0.17,0.566-0.202,0.858H93.026z"
        />
        <path
          fill="#1D1D1B"
          d="M99.214,79.384c-0.929-0.021-1.587-0.222-1.977-0.601c-0.388-0.378-0.583-0.966-0.583-1.765V66.909
	l1.507-0.259v10.125c0,0.248,0.021,0.453,0.065,0.615c0.043,0.162,0.113,0.292,0.21,0.389c0.097,0.098,0.227,0.17,0.389,0.219
	c0.162,0.05,0.362,0.09,0.599,0.121L99.214,79.384z"
        />
        <path
          fill="#1D1D1B"
          d="M101.774,69.437c-0.27,0-0.5-0.089-0.689-0.268c-0.189-0.178-0.283-0.418-0.283-0.721
	c0-0.302,0.094-0.542,0.283-0.721c0.189-0.179,0.419-0.268,0.689-0.268s0.499,0.089,0.688,0.268s0.284,0.419,0.284,0.721
	c0,0.303-0.095,0.543-0.284,0.721C102.273,69.348,102.044,69.437,101.774,69.437 M102.536,79.384h-1.507v-8.425h1.507V79.384z"
        />
        <path
          fill="#1D1D1B"
          d="M110.328,78.541c-0.129,0.087-0.381,0.198-0.754,0.332c-0.373,0.136-0.807,0.203-1.304,0.203
	c-0.508,0-0.985-0.081-1.434-0.243s-0.841-0.413-1.174-0.753c-0.335-0.341-0.6-0.765-0.794-1.272
	c-0.195-0.508-0.292-1.111-0.292-1.813c0-0.616,0.092-1.181,0.276-1.693c0.183-0.513,0.45-0.956,0.801-1.329
	c0.351-0.372,0.781-0.664,1.288-0.874c0.508-0.211,1.08-0.315,1.719-0.315c0.701,0,1.313,0.051,1.838,0.152
	c0.524,0.104,0.964,0.198,1.32,0.284v7.517c0,1.297-0.334,2.235-1.005,2.819c-0.669,0.583-1.685,0.874-3.045,0.874
	c-0.53,0-1.028-0.043-1.499-0.129c-0.47-0.087-0.877-0.189-1.223-0.308l0.275-1.313c0.303,0.118,0.672,0.224,1.11,0.316
	c0.438,0.091,0.895,0.137,1.369,0.137c0.896,0,1.541-0.178,1.936-0.534s0.592-0.924,0.592-1.701V78.541z M110.311,72.288
	c-0.151-0.043-0.354-0.083-0.606-0.122c-0.254-0.037-0.599-0.057-1.029-0.057c-0.811,0-1.434,0.266-1.871,0.794
	c-0.438,0.529-0.657,1.231-0.657,2.106c0,0.486,0.062,0.902,0.187,1.247c0.125,0.346,0.291,0.632,0.503,0.859
	c0.211,0.227,0.454,0.394,0.729,0.502c0.274,0.107,0.559,0.162,0.85,0.162c0.4,0,0.767-0.058,1.102-0.171s0.6-0.245,0.794-0.396
	V72.288z"
        />
        <path
          fill="#1D1D1B"
          d="M113.859,74.993c0-0.745,0.107-1.396,0.324-1.952c0.217-0.556,0.502-1.018,0.857-1.385
	c0.357-0.367,0.768-0.642,1.232-0.826c0.465-0.184,0.939-0.275,1.426-0.275c1.133,0,2.004,0.354,2.607,1.061
	c0.605,0.708,0.907,1.785,0.907,3.232V75.1c0,0.102-0.005,0.195-0.015,0.282h-5.769c0.065,0.875,0.317,1.539,0.763,1.993
	c0.441,0.453,1.133,0.681,2.072,0.681c0.529,0,0.975-0.047,1.337-0.139c0.361-0.091,0.634-0.181,0.817-0.267l0.211,1.264
	c-0.184,0.097-0.506,0.199-0.965,0.308s-0.979,0.161-1.563,0.161c-0.734,0-1.367-0.11-1.902-0.332
	c-0.535-0.221-0.976-0.525-1.321-0.915c-0.345-0.389-0.603-0.85-0.769-1.385C113.943,76.216,113.859,75.63,113.859,74.993
	 M119.642,74.167c0.012-0.681-0.158-1.239-0.51-1.677s-0.835-0.656-1.45-0.656c-0.346,0-0.649,0.068-0.915,0.203
	c-0.265,0.135-0.488,0.311-0.673,0.526c-0.183,0.216-0.326,0.464-0.429,0.745c-0.104,0.28-0.17,0.566-0.203,0.858H119.642z"
        />
        <path
          fill="#1D1D1B"
          d="M123.303,71.202c0.347-0.086,0.806-0.178,1.377-0.274c0.572-0.099,1.231-0.146,1.978-0.146
	c0.668,0,1.225,0.095,1.668,0.283c0.442,0.19,0.795,0.454,1.061,0.794c0.266,0.341,0.451,0.748,0.561,1.223
	c0.106,0.476,0.16,0.999,0.16,1.572v4.73h-1.506v-4.406c0-0.52-0.035-0.962-0.105-1.329s-0.187-0.664-0.349-0.891
	c-0.161-0.227-0.378-0.392-0.647-0.494c-0.271-0.104-0.605-0.154-1.006-0.154c-0.162,0-0.328,0.006-0.502,0.016
	c-0.172,0.013-0.336,0.025-0.494,0.041c-0.156,0.017-0.297,0.035-0.42,0.058c-0.125,0.021-0.213,0.038-0.268,0.048v7.112h-1.507
	V71.202z"
        />
        <path
          fill="#1D1D1B"
          d="M133.931,70.766h3.191v1.263h-3.191v3.888c0,0.423,0.033,0.771,0.098,1.045
	c0.064,0.276,0.162,0.492,0.291,0.648c0.131,0.157,0.293,0.267,0.486,0.332s0.421,0.097,0.682,0.097c0.453,0,0.817-0.05,1.092-0.152
	c0.276-0.104,0.468-0.177,0.576-0.22l0.291,1.247c-0.151,0.076-0.416,0.17-0.793,0.284c-0.379,0.113-0.811,0.17-1.297,0.17
	c-0.572,0-1.045-0.073-1.416-0.219c-0.373-0.146-0.674-0.364-0.9-0.656c-0.227-0.291-0.387-0.649-0.478-1.077
	c-0.093-0.427-0.138-0.921-0.138-1.482v-7.517l1.506-0.259V70.766z"
        />
        <path
          fill="#1D1D1B"
          d="M138.953,71.202c0.347-0.086,0.806-0.178,1.377-0.274c0.572-0.099,1.231-0.146,1.978-0.146
	c0.669,0,1.226,0.095,1.668,0.283c0.442,0.19,0.796,0.454,1.062,0.794c0.265,0.341,0.451,0.748,0.56,1.223
	c0.106,0.476,0.161,0.999,0.161,1.572v4.73h-1.506v-4.406c0-0.52-0.035-0.962-0.106-1.329c-0.069-0.367-0.187-0.664-0.348-0.891
	c-0.162-0.227-0.378-0.392-0.647-0.494c-0.271-0.104-0.605-0.154-1.006-0.154c-0.162,0-0.328,0.006-0.502,0.016
	c-0.172,0.013-0.337,0.025-0.494,0.041c-0.156,0.017-0.297,0.035-0.421,0.058c-0.125,0.021-0.213,0.038-0.267,0.048v7.112h-1.508
	V71.202z"
        />
        <path
          fill="#1D1D1B"
          d="M146.892,80.955c0.119,0.054,0.271,0.104,0.462,0.153c0.188,0.05,0.375,0.073,0.56,0.073
	c0.593,0,1.058-0.132,1.393-0.396c0.335-0.266,0.637-0.694,0.907-1.288c-0.681-1.296-1.315-2.67-1.903-4.123
	c-0.589-1.452-1.076-2.924-1.467-4.414h1.62c0.118,0.486,0.262,1.01,0.43,1.571c0.167,0.562,0.353,1.14,0.56,1.733
	c0.205,0.594,0.426,1.188,0.664,1.781c0.236,0.595,0.485,1.167,0.744,1.718c0.411-1.134,0.768-2.257,1.069-3.37
	c0.304-1.111,0.589-2.257,0.858-3.434h1.556c-0.39,1.587-0.821,3.113-1.296,4.576c-0.475,1.464-0.988,2.833-1.539,4.106
	c-0.217,0.486-0.439,0.904-0.672,1.255c-0.232,0.352-0.489,0.641-0.77,0.867c-0.281,0.228-0.598,0.395-0.947,0.502
	c-0.352,0.108-0.75,0.162-1.191,0.162c-0.119,0-0.243-0.008-0.373-0.023c-0.129-0.017-0.257-0.038-0.381-0.065
	s-0.237-0.056-0.34-0.089c-0.102-0.032-0.176-0.06-0.219-0.081L146.892,80.955z"
        />
        <path
          fill="#1D1D1B"
          d="M165.101,74.686c0.174,0.216,0.391,0.5,0.656,0.85c0.265,0.352,0.537,0.74,0.818,1.167
	c0.28,0.427,0.559,0.872,0.834,1.337s0.511,0.912,0.705,1.345h-1.718c-0.216-0.41-0.45-0.826-0.704-1.248
	c-0.254-0.421-0.511-0.823-0.77-1.206c-0.26-0.384-0.516-0.743-0.77-1.078c-0.254-0.334-0.483-0.626-0.688-0.874
	c-0.142,0.01-0.284,0.016-0.431,0.016h-1.854v4.391h-1.57V68.318c0.441-0.106,0.936-0.18,1.481-0.218
	c0.545-0.038,1.044-0.057,1.498-0.057c1.577,0,2.778,0.297,3.604,0.891s1.239,1.48,1.239,2.657c0,0.745-0.196,1.382-0.591,1.912
	C166.449,74.032,165.867,74.427,165.101,74.686 M162.72,69.421c-0.67,0-1.184,0.016-1.539,0.048v4.229h1.117
	c0.54,0,1.025-0.026,1.458-0.081c0.433-0.054,0.797-0.156,1.094-0.308s0.526-0.365,0.688-0.64c0.162-0.275,0.244-0.641,0.244-1.095
	c0-0.421-0.082-0.771-0.244-1.052c-0.162-0.281-0.381-0.503-0.656-0.665s-0.6-0.274-0.973-0.34S163.141,69.421,162.72,69.421"
        />
        <path
          fill="#1D1D1B"
          d="M177.055,74.978c0,0.669-0.097,1.273-0.291,1.813s-0.468,1.005-0.817,1.395
	c-0.352,0.388-0.771,0.688-1.256,0.897c-0.486,0.211-1.016,0.316-1.588,0.316s-1.101-0.105-1.588-0.316
	c-0.486-0.21-0.904-0.51-1.256-0.897c-0.35-0.39-0.623-0.854-0.816-1.395c-0.195-0.54-0.293-1.145-0.293-1.813
	c0-0.659,0.098-1.262,0.293-1.808c0.193-0.545,0.467-1.012,0.816-1.4c0.352-0.39,0.77-0.688,1.256-0.899
	c0.487-0.21,1.016-0.315,1.588-0.315s1.102,0.105,1.588,0.315c0.485,0.211,0.904,0.51,1.256,0.899c0.35,0.389,0.623,0.855,0.817,1.4
	C176.958,73.716,177.055,74.318,177.055,74.978 M175.484,74.978c0-0.95-0.213-1.704-0.64-2.26c-0.427-0.557-1.006-0.834-1.741-0.834
	c-0.734,0-1.314,0.277-1.742,0.834c-0.427,0.556-0.64,1.31-0.64,2.26s0.213,1.704,0.64,2.26c0.428,0.556,1.008,0.834,1.742,0.834
	c0.735,0,1.314-0.278,1.741-0.834S175.484,75.928,175.484,74.978"
        />
        <path
          fill="#1D1D1B"
          d="M184.361,72.094c-0.227,0.249-0.525,0.6-0.897,1.053c-0.373,0.454-0.772,0.956-1.2,1.507
	c-0.426,0.55-0.854,1.128-1.279,1.733c-0.428,0.604-0.803,1.183-1.125,1.732h4.633v1.265h-6.433V78.38
	c0.261-0.476,0.578-0.999,0.956-1.572c0.378-0.572,0.775-1.143,1.191-1.709c0.416-0.567,0.82-1.103,1.215-1.604
	s0.736-0.925,1.029-1.271h-4.164V70.96h6.074V72.094z"
        />
        <path
          fill="#1D1D1B"
          d="M193.207,79.384c-0.303-0.778-0.623-1.666-0.965-2.665c-0.34-0.999-0.649-2.038-0.932-3.118
	c-0.279,1.08-0.587,2.119-0.922,3.118s-0.654,1.887-0.956,2.665h-1.313c-0.496-1.112-0.981-2.381-1.457-3.808
	c-0.476-1.425-0.924-2.964-1.344-4.616h1.618c0.109,0.508,0.239,1.059,0.39,1.652c0.151,0.593,0.311,1.188,0.479,1.781
	c0.167,0.594,0.342,1.167,0.525,1.718c0.185,0.55,0.356,1.031,0.52,1.441c0.184-0.507,0.364-1.044,0.543-1.611
	c0.178-0.567,0.348-1.14,0.51-1.718c0.161-0.577,0.316-1.145,0.461-1.701c0.146-0.556,0.273-1.077,0.381-1.563h1.248
	c0.098,0.485,0.215,1.007,0.356,1.563c0.14,0.557,0.292,1.124,0.454,1.701c0.162,0.578,0.332,1.15,0.509,1.718
	c0.179,0.567,0.36,1.104,0.543,1.611c0.163-0.41,0.332-0.892,0.511-1.441c0.178-0.551,0.354-1.124,0.527-1.718
	c0.171-0.593,0.334-1.188,0.485-1.781c0.151-0.594,0.28-1.145,0.389-1.652h1.556c-0.422,1.652-0.869,3.191-1.345,4.616
	c-0.476,1.427-0.961,2.695-1.459,3.808H193.207z"
        />
        <path
          fill="#1D1D1B"
          d="M206.068,74.978c0,0.669-0.096,1.273-0.291,1.813c-0.193,0.54-0.468,1.005-0.818,1.395
	c-0.35,0.388-0.77,0.688-1.256,0.897c-0.484,0.211-1.015,0.316-1.586,0.316c-0.574,0-1.103-0.105-1.588-0.316
	c-0.486-0.21-0.904-0.51-1.257-0.897c-0.35-0.39-0.622-0.854-0.817-1.395c-0.193-0.54-0.291-1.145-0.291-1.813
	c0-0.659,0.098-1.262,0.291-1.808c0.195-0.545,0.468-1.012,0.817-1.4c0.353-0.39,0.771-0.688,1.257-0.899
	c0.485-0.21,1.014-0.315,1.588-0.315c0.571,0,1.102,0.105,1.586,0.315c0.486,0.211,0.906,0.51,1.256,0.899
	c0.351,0.389,0.625,0.855,0.818,1.4C205.972,73.716,206.068,74.318,206.068,74.978 M204.498,74.978c0-0.95-0.213-1.704-0.641-2.26
	c-0.427-0.557-1.007-0.834-1.74-0.834c-0.735,0-1.315,0.277-1.742,0.834c-0.428,0.556-0.641,1.31-0.641,2.26s0.213,1.704,0.641,2.26
	c0.427,0.556,1.007,0.834,1.742,0.834c0.733,0,1.313-0.278,1.74-0.834C204.285,76.682,204.498,75.928,204.498,74.978
	 M204.076,67.769l-2.283,2.219l-0.762-0.68l2.073-2.495L204.076,67.769z"
        />
        <path
          fill="#1D1D1B"
          d="M206.732,82.413c-0.13,0-0.297-0.017-0.502-0.048c-0.205-0.033-0.378-0.076-0.519-0.131l0.194-1.23
	c0.108,0.032,0.232,0.059,0.373,0.081c0.139,0.021,0.27,0.032,0.389,0.032c0.519,0,0.888-0.159,1.109-0.479
	c0.223-0.317,0.332-0.785,0.332-1.4V70.96h1.508v8.262c0,1.08-0.246,1.882-0.738,2.405C208.387,82.15,207.671,82.413,206.732,82.413
	 M208.854,69.437c-0.271,0-0.499-0.089-0.688-0.267c-0.188-0.178-0.283-0.419-0.283-0.721c0-0.304,0.095-0.543,0.283-0.722
	c0.189-0.178,0.418-0.268,0.688-0.268s0.5,0.09,0.688,0.268c0.189,0.179,0.285,0.418,0.285,0.722c0,0.302-0.096,0.543-0.285,0.721
	C209.354,69.348,209.125,69.437,208.854,69.437"
        />
      </g>
    </Fe>
  )
}
