export const godziny = [
  {
    id: 1,
    day: "PN",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
  {
    id: 2,
    day: "WT",
    godzinaOd: "7:00",
    godzinaDo: "17:00",
  },
]

export const info = {
  nazwa: "3DevS Sp. z o. o.",
  ulica: "Konarskiego",
  nrBud: "18C",
  kod: "44-100",
  miasto: "Gliwice",
  woj: "Śląskie",
  kraj: "Polska",
  tel: "881 210 654",
  mail: "biuro@3devs.pl",
  nip: "631 26 826 79",
  regon: "381756211",
  krs: "0000756745",
}
