import React, { useState } from "react"
import { ThemeProvider } from "styled-components"
//STYLES
import { GlobalStyle } from "../utils/globalStye"
import { themeLight } from "../utils/theme"
import { themeContrast } from "../utils/themeContrast"
//DATA
import { info } from "../assets/data/kontakt"
//COOKIES
import Cookie from "../components/cookie/Cookie"
//NAVBAR
import Navbar from "../components/navbar/Navbar_Wcag"
//import NavbarHeader from '../components/navbar/NavbarHeader_Simple'
//FOOTER
import Footer from "../components/footer/footer/footer"
import FooterHeader from "../components/footer/footerHeader/FooterHeader"
import NavbarHeader from "../components/navbar/NavbarHeader_UE"

const Layout = props => {
  const [theme, setTheme] = useState("light")
  const themeToggler = () => {
    theme === "light" ? setTheme("dark") : setTheme("light")
  }

  const [fontSize, setSize] = useState("normal")
  const biggerFont = () => {
    fontSize === "normal"
      ? setSize("big")
      : "small"
      ? setSize("big")
      : setSize("normal")
  }
  const smallerFont = () => {
    fontSize === "normal"
      ? setSize("small")
      : "big"
      ? setSize("small")
      : setSize("normal")
  }
  const normalFont = () => {
    fontSize === !"normal" ? setSize("normal") : setSize("normal")
  }

  return (
    <ThemeProvider theme={theme === "light" ? themeLight : themeContrast}>
      <GlobalStyle
        size={fontSize === "normal" ? 10 : fontSize === "big" ? 15 : 8}
      />
      <div>
        <NavbarHeader />
        <Navbar
          color_font_active={theme === "light" ? "red" : "blue"}
          color_font={theme === "light" ? "" : "black"}
          contrast={themeToggler}
          big={biggerFont}
          small={smallerFont}
          normal={normalFont}
          fs={fontSize}
          th={theme}
        />
        <div>{props.children}</div>
        <Cookie />
        <FooterHeader
          heading={footer.heading}
          subHeading={footer.subHeading}
          hrOpacity="0"
        />
        <Footer
          tel={info.tel}
          email={info.mail}
          ulica={info.ulica}
          nrBud={info.nrBud}
          miasto={info.miasto}
          kod={info.kod}
          woj={info.woj}
          kraj={info.kraj}
        />
      </div>
    </ThemeProvider>
  )
}
export default Layout

export const footer = {
  heading: "Zapraszmy do kontaktu!",
  subHeading:
    "Chętnie odpowiemy na Twoje pytania i rozwiejemy wątpliwości.\nZapraszamy do kontaktu w celu zaprezentowania systemu.\nWspólnie pokonamy trudności!",
}
