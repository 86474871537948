import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { down } from "styled-breakpoints"
//DATA
import { list } from "../../assets/data/pageStructure"
//STYLED
import { Container } from "../../utils/utils"
//COMPONENTS
import NavMobile from "./navbarMobile/NavMobile"
import HamburgerMenuIcon from "./navbarMobile/HamburgerMenuIcon"
import Wcag from "./Wcag"

const navbarItemCount = Object.keys(list).length
const navbarHeight = "70px"

const Nav = styled.nav`
  position: sticky;
  top: 0;
  z-index: 2;
  height: ${navbarHeight};
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  //box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid
    ${props => props.spanColor || props.theme.color.grey8};
  border-top: 1px solid ${props => props.spanColor || props.theme.color.grey8};
  ${down("sm")} {
    display: none;
  }
`

const NavbarWrapper = styled(Container)`
  display: flex;
  justify-content: space-between;
`

const WcagWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const NavbarItemWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(${navbarItemCount}, minmax(100px, 1fr));
  justify-self: center;

  li {
    height: 100%;
    display: flex;
    margin: 0;
  }
`

const NavbarItem = styled(Link)`
  text-align: center;
  padding: 0 1.5rem;
  display: grid;
  align-items: center;
  width: 100%;
  white-space: pre-wrap;

  background-color: transparent;
  //color: ${({ theme }) => theme.color.font};
  color: ${props => props.color_font || props.theme.color.grey1};
  font-size: ${props => props.xl || props.theme.font.size.h7.xs};
  font-weight: 400;
  line-height: 1.1;
  border-style: solid;
  border-width: 0 0 5px 0;
  border-color: transparent;
  transition: all 0.5s;

  &.active,
  :hover {
    //color: ${({ theme }) => theme.color.base1};
    color: ${props => props.color_font_active || props.theme.color.base11};
    border-color: ${props =>
      props.color_font_active || props.theme.color.base11};
    //border-color: ${({ theme }) => theme.color.base1};
  }

  :focus {
    outline-color: ${({ theme }) => theme.color.grey5};
    outline-width: 2px;
    outline-style: solid;
    background-color: transparent;
  }
`

const Logo = styled(Link)`
  padding: 0.5rem 1rem 0.5rem 0;
  display: block;
  height: ${navbarHeight};
  div {
    height: 100% !important;
  }

  :focus {
    outline-color: "grey";
    outline-width: 2px;
    outline-style: solid;
    background-color: transparent;
  }
`

class Navbar extends React.Component {
  state = { clicked: false }

  handleClick = () => {
    this.setState({ clicked: !this.state.clicked })
  }

  render() {
    const menuMob = list.map(item => (
      <li onClick={this.handleClick} state={this.state.clicked} key={item.name}>
        <NavbarItem activeClassName="active" tabIndex="0" to={item.path}>
          {item.name}
        </NavbarItem>
      </li>
    ))

    return (
      <>
        {/*BELKA NAWIGACJI*/}
        <Wcag
          contrast={this.props.contrast}
          big={this.props.big}
          small={this.props.small}
          normal={this.props.normal}
          fs={this.props.fs}
          th={this.props.th}
        />

        <Nav id="menu" role="navigation" aria-label="main navigation">
          <NavbarWrapper>
            <WcagWrapper>
              <Logo to="/">
                <StaticImage
                  src="../../assets/images/logo/logo.png"
                  alt="Logo"
                  objectPosition="center left"
                  objectFit="contain"
                  placeholder="blurred"
                />
              </Logo>
              {/* <Wcag
                            contrast={this.props.contrast}
                            big={this.props.big}
                            small={this.props.small}
                            normal={this.props.normal} /> */}
            </WcagWrapper>

            <NavbarItemWrapper>{menuMob}</NavbarItemWrapper>
          </NavbarWrapper>
        </Nav>

        {/*LOGO MOBILNE*/}
        {/* <LogoMobile /> */}

        {/*MENU MOBILNE*/}
        {/* <motion.div
                animate={this.state.clicked ? "open" : "closed"}
                variants={variants}>

                <NavMobile>
                    {menu}
                </NavMobile>
            </motion.div> */}

        {this.state.clicked ? (
          <NavMobile
            top="0"
            opacity="1"
            theme={this.props}
            onClick={this.handleClick}
            state={this.state.clicked}
          >
            {menuMob}
          </NavMobile>
        ) : (
          <NavMobile></NavMobile>
        )}

        {/*IKONKA HAMBURGER MENU POJAWIAJĄCE SIĘ TYLKO NA MOBILNYCH*/}
        <HamburgerMenuIcon
          onClick={this.handleClick}
          state={this.state.clicked}
        />
      </>
    )
  }
}
export default Navbar
