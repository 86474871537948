import React from "react"
import styled from "styled-components"
import { H3 } from "../../../utils/typo"
import { Container } from "../../../utils/utils"

const FooterHeaderWrapper = styled.div`
  background: ${props => props.bgColor || props.theme.color.sec2};
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  p {
    color: ${props => props.theme.color.white};
    white-space: pre-wrap;
  }

  hr {
    margin-bottom: 0;
    margin-top: 4rem;
    opacity: ${props => props.hrOpacity || 0.8};
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
`

const FooterHeader = ({ heading, subHeading, bgColor, hrOpacity }) => {
  return (
    <FooterHeaderWrapper bgColor={bgColor} hrOpacity={hrOpacity}>
      <Container>
        <H3 as="p">{heading}</H3>
        <p>{subHeading}</p>
        <hr />
      </Container>
    </FooterHeaderWrapper>
  )
}
export default FooterHeader
