import styled from "styled-components"
import { down } from "styled-breakpoints"

export const Container = styled.div`
  width: 100%;
  //padding-right: 15px;
  //padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;

  ${down("lg")} {
    max-width: 1200px;
  }

  ${down("md")} {
    max-width: 960px;
  }

  ${down("sm")} {
    max-width: 720px;
  }

  ${down("xs")} {
    max-width: 540px;
  }
`

export const ContainerMob = styled.div`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  max-width: 1600px;

  ${down("lg")} {
    max-width: 1200px;
  }

  ${down("md")} {
    max-width: 960px;
  }

  ${down("sm")} {
    max-width: 720px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  ${down("xs")} {
    max-width: 540px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`

export const ContainerHalf = styled.div`
  width: 100%;
  margin-right: 0;
  margin-left: auto;
  max-width: 800px;

  ${down("lg")} {
    max-width: 600px;
  }

  ${down("md")} {
    max-width: 480px;
  }

  ${down("sm")} {
    max-width: 360px;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  ${down("xs")} {
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1rem;
    padding-left: 1rem;
  }
`

export const CardWrapper = styled(Container)`
  display: grid;
  padding-left: 0;
  padding-right: 0;
  grid-column-gap: ${props => props.colGap || "0"};
  grid-row-gap: ${props => props.rowGap || "0rem"};
  margin-top: ${props => props.mt || "0rem"};
  margin-bottom: ${props => props.mb || "0rem"};
  padding-top: ${props => props.pt || "0"};
  padding-bottom: ${props => props.pb || "0"};
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(${props => props.xl || 5}, 1fr);

  ${down("lg")} {
    grid-template-columns: repeat(${props => props.l || 4}, 1fr);
  }

  ${down("md")} {
    grid-template-columns: repeat(${props => props.m || 3}, 1fr);
  }

  ${down("sm")} {
    grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }

  ${down("xs")} {
    grid-template-columns: repeat(${props => props.xs || 1}, 1fr);
  }
`

export const CardWrapperMob = styled(ContainerMob)`
  display: grid;
  grid-column-gap: ${props => props.colGap || "0"};
  grid-row-gap: ${props => props.rowGap || "0rem"};
  margin-top: ${props => props.mt || "0rem"};
  margin-bottom: ${props => props.mb || "0rem"};
  padding-top: ${props => props.pt || "0"};
  padding-bottom: ${props => props.pb || "0"};
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(${props => props.xl || 5}, 1fr);

  ${down("lg")} {
    grid-template-columns: repeat(${props => props.l || 4}, 1fr);
  }

  ${down("md")} {
    grid-template-columns: repeat(${props => props.m || 3}, 1fr);
  }

  ${down("sm")} {
    grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }

  ${down("xs")} {
    grid-template-columns: repeat(${props => props.xs || 1}, 1fr);
  }
`

export const CardWrapperFull = styled.div`
  display: grid;
  padding-left: 0;
  padding-right: 0;
  grid-column-gap: ${props => props.colGap || "0"};
  grid-row-gap: ${props => props.rowGap || "0"};
  margin-top: ${props => props.mt || "0"};
  margin-bottom: ${props => props.mb || "0"};
  padding-top: ${props => props.pt || "0"};
  padding-bottom: ${props => props.pb || "0"};
  grid-template-columns: repeat(3, 1fr);
  grid-template-columns: repeat(${props => props.xl || 5}, 1fr);

  ${down("lg")} {
    grid-template-columns: repeat(${props => props.l || 4}, 1fr);
  }

  ${down("md")} {
    grid-template-columns: repeat(${props => props.m || 3}, 1fr);
  }

  ${down("sm")} {
    grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }

  ${down("xs")} {
    grid-template-columns: repeat(${props => props.xs || 1}, 1fr);
  }
`

export const Section = styled.section`
  padding-top: ${props => props.pt || "0rem"};
  padding-bottom: ${props => props.pb || "0rem"};
  background-color: ${props => props.bgcolor || "transparent"};
  position: relative;

  ${down("lg")} {
    //grid-template-columns: repeat(${props => props.l || 4}, 1fr);
  }

  ${down("md")} {
    //grid-template-columns: repeat(${props => props.m || 3}, 1fr);
  }

  ${down("sm")} {
    //grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }

  ${down("xs")} {
    //grid-template-columns: repeat(${props => props.s || 2}, 1fr);
  }
`

export const Section2 = styled.section`
  padding-top: ${props => `${props.pt}rem` || "0rem"};
  padding-bottom: ${props => `${props.pb}rem` || "0rem"};
  background-color: ${props => props.bgcolor || "transparent"};
  position: relative;

  ${down("lg")} {
    padding-top: ${props => `${props.pt}rem` || "0rem"};
    padding-bottom: ${props => `${props.pb}rem` || "0rem"};
  }

  ${down("md")} {
    padding-top: ${props => `${props.pt}rem` || "0rem"};
    padding-bottom: ${props => `${props.pb}rem` || "0rem"};
  }

  ${down("sm")} {
    padding-top: ${props => `${props.pt - 3}rem` || "0rem"};
    padding-bottom: ${props => `${props.pb}rem` || "0rem"};
  }

  ${down("xs")} {
    padding-top: ${props => `${props.pt - 4}rem` || "0rem"};
    padding-bottom: ${props => `${props.pb}rem` || "0rem"};
  }
`

export const Div = styled.div`
  padding-top: ${props => props.pt || "0rem"};
  padding-bottom: ${props => props.pb || "0rem"};
  padding-left: ${props => props.pl || "0"};
  padding-right: ${props => props.pr || "0"};
  margin-top: ${props => props.mt || "0"};
  margin-bottom: ${props => props.mb || "0"};
  margin-left: ${props => props.ml || "0"};
  margin-right: ${props => props.mr || "0"};
  background-color: ${props => props.bgcolor || "transparent"};
`

export const Logo = styled.div`
  ${down("sm")} {
    grid-column: 1;
    padding: 0 2rem 2rem 2rem;
    ::before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 90px;
      margin: 0px -2rem 2rem -2rem;
      opacity: 0.8;
      display: block;
      background-color: ${props => props.bgcolor || props.theme.color.white};
    }
  }
`

export const Ratio = styled.canvas`
  width: 100%;
  margin: 0;
  display: block;
`

export const ContentRatio = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
`

export const Pattern = styled.div`
  height: 15vh;
  background-color: transparent;
  background-size: 15px 15px;
  background-image: repeating-linear-gradient(
    45deg,
    rgba(1, 1, 1, 0.1) 0,
    rgba(1, 1, 1, 0.1) 1.3px,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0) 50%
  );
`
