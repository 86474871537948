import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons"

import { list } from "../../../assets/data/pageStructure"

import styled from "styled-components"
import { down } from "styled-breakpoints"
import { Container } from "../../../utils/utils"

const Foot = styled.footer`
  text-align: center;
  padding-top: 2rem;
  background-color: ${props => props.bgColor || props.theme.color.sec1};
`

const FooterWrapper = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${down("sm")} {
    grid-template-columns: repeat(2, 1fr);
  }

  ${down("xs")} {
    grid-template-columns: repeat(1, 1fr);
  }

  p,
  li,
  a {
    color: ${({ theme }) => theme.color.white};
    white-space: pre-wrap;
  }
`

const FooterLogo = styled.div`
  display: flex;
  //justify-content: space-around;
  align-items: flex-start;

  div {
    width: 80% !important;
    //height: auto !important;
  }
  ${down("sm")} {
    display: none;
  }
`

const FooterAdres = styled.div`
  ${down("xs")} {
    display: none;
  }
  p {
    :first-child {
      font-weight: 700;
    }
  }
`

const FooterKontakt = styled.div`
  a {
    font-weight: 700;
    transition: all 0.4s ease-in-out;

    :hover {
      font-weight: 700;
      color: ${({ theme }) => theme.color.prim2};
    }
  }
  div {
    margin-top: 2rem;

    a {
      font-size: 30px;
      margin: 0 1rem;
    }
  }
`

const FooterLinki = styled.ul`
  list-style-type: none;
  line-height: 1;
  text-align: right;
  ${down("sm")} {
    display: none;
  }

  li {
    margin-bottom: 0.8rem;
    font-size: ${({ theme }) => theme.font.size.p.xs};
  }

  a {
    font-weight: 400;
    font-size: ${({ theme }) => theme.font.size.p.xs};
    line-height: 1;
    transition: all 0.4s ease-in-out;

    :hover {
      font-weight: 500;
      color: ${({ theme }) => theme.color.prim2};
    }
  }
`

const Footer = ({
  tel,
  email,
  ulica,
  nrBud,
  miasto,
  kod,
  woj,
  kraj,
  bgColor,
}) => {
  const menu = list.map(item => (
    <li key={item.name}>
      <Link to={item.path}>{item.name}</Link>
    </li>
  ))

  return (
    <Foot bgColor={bgColor}>
      <FooterWrapper>
        <FooterLogo>
          <StaticImage
            src="../../../assets/images/logo/logo_white.png"
            alt="Logo"
            objectFit="contain"
            placeholder="blurred"
          />
        </FooterLogo>

        <FooterAdres>
          <p>Adres</p>
          <p>
            ul. {ulica} {nrBud}
          </p>
          <p>
            {kod} {miasto}
          </p>
          <p>Województwo {woj}</p>
          <p>{kraj}</p>
        </FooterAdres>

        <FooterKontakt>
          <Link to="/kontakt">Kontakt</Link>
          <p>+48 {tel}</p>
          <p>{email}</p>
        </FooterKontakt>

        <FooterLinki>{menu}</FooterLinki>
      </FooterWrapper>
    </Foot>
  )
}
export default Footer
